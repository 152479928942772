import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import BillingDetails from './BillingDetails'
import CardDetails from './CardDeatils'
import { useCreateSubscriptionMutation } from 'App/Modules/Services/Payments'
import './checkout.css'
import { decrypt, encrypt } from 'config'
import { toast } from 'react-toastify'
import Loader from 'App/Loaders/BeatLoader'

interface Plan {
  id: string
  name: string
  priceYearly: number
  assetCount: number
  isEnterprise: boolean
  description?: string
  isYearly?: boolean
  priceMonthly?: number
}

function CheckoutPayment() {
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null)
  const [billingData, setBillingData] = useState({})
  const [cardData, setCardData] = useState({})
  const [loading, setLoading] = useState(true)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isBillingValid, setIsBillingValid] = useState(false)

  const navigate = useNavigate()
  const [createSubscription, { isLoading, isError, isSuccess }] = useCreateSubscriptionMutation()
  const storedPlan = localStorage.getItem('selectedPlan')

  useEffect(() => {
    if (storedPlan) {
      setLoading(false)
      const encryptedPlan = JSON?.parse(storedPlan)
      const decryptedPlan = JSON?.parse(decrypt(encryptedPlan))
      setSelectedPlan(decryptedPlan)
    }
    else{
      setLoading(false)
    }
  }, [storedPlan])

  const handleBillingChange = (data: any) => {
    setBillingData(data)
  }

  const handleCardChange = (data: any) => {
    setCardData(data)
  }
  const handleCancel = () => {
    localStorage.removeItem('selectedPlan')
    navigate('/pricing')
  }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const encryptedBillingData = {}
    const encryptedCardData = {}

    for (const [key, value] of Object.entries(billingData)) {
      encryptedBillingData[key] = encrypt(value)
    }

    for (const [key, value] of Object.entries(cardData)) {
      encryptedCardData[key] = encrypt(value)
    }

    const body = {
      ...encryptedBillingData,
      ...encryptedCardData,
      planId: selectedPlan?.id,
      isYearly: selectedPlan?.isYearly,
    }
    try {
      const response = await createSubscription(body).unwrap()
      if (response.success) {
        window.location.href = response?.paymentVerificationUrl
        setBillingData({})
        setCardData({})
        localStorage.removeItem('selectedPlan')
        toast.success('You are being redirect to your bank for confirm payment')
      }
    } catch (error: any) {
      toast.error('Payment failed. Please try again.', error)
    }
  }

  return (
    <>
      {
        !selectedPlan && !loading ? (
          <div className='main-success'>
            <div className='failure-container'>
              <div className='failure-icon d-flex justify-content-center align-items-center'>
                <div className='success-bg failure-bg d-flex justify-content-center align-items-center'>
                  <i className='fa-regular fa-face-frown'></i>
                </div>
              </div>
              <h2 className='success-title text-center'>No Plan Selected</h2>
              <p className='failure-description mb-0'>
                No plan selected. Please go back and select a plan.
              </p>
            </div>
          </div>
        ) : (
          <div className='checkout-container'>
            <div className='container'>
              <Row>
                <Col xl='8'>
                  <div className='card'>
                    <div
                      className='card-header border-0'
                      data-bs-target='#kt_account_profile_details'
                      aria-expanded='true'
                      aria-controls='kt_account_profile_details'
                    >
                      <div className='card-title m-0'>
                        <span className='me-2 align-middle'>
                          <i className='la la-plus-circle fs-1'></i>
                        </span>
                        <h3 className='fw-bolder m-0'>Payment</h3>
                      </div>
                    </div>
                    <div id='kt_account_profile_details' className='collapse show'>
                      <form onSubmit={handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-lg-9 p-md-7 p-6'>
                          <div className='pb-5'>
                            <h3 className='fw-bolder m-0 mb-3'>Enter Payment Details</h3>
                          </div>
                          <BillingDetails
                            onBillingChange={handleBillingChange}
                            setIsBillingValid={setIsBillingValid}
                          />
                          <div className='pb-5'>
                            <h3 className='fw-bolder m-0 mb-3'>Enter Credit Card Information</h3>
                          </div>
                          <CardDetails
                            onCardChange={handleCardChange}
                            setIsFormValid={setIsFormValid}
                          />
                          <div className='d-flex justify-content-end py-6 px-9'>
                            <button
                              className='btn btn-primary me-2'
                              type='submit'
                              disabled={!isFormValid || !isBillingValid}
                            >
                              {isLoading ? (
                                <span className='indicator-progress d-block'>Processing...</span>
                              ) : (
                                <span className='indicator-progress d-block'>Pay Now</span>
                              )}
                            </button>
                            <button
                              type='button'
                              className='btn btn-light-primary main-btn-style'
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
                <Col xl='4'>
                  <div className='billing-bg'>
                    <h3 className='fw-bolder m-0 mb-3'>{selectedPlan?.name}</h3>
                    <p>{selectedPlan?.description}</p>
                    <h5 className='mb-3'>What's included in {selectedPlan?.name}?</h5>
                    <ul className='ps-0'>
                      <li className='d-flex mb-3'>
                        <i className='fa-solid fa-check'></i>
                        <span className='ms-3'>
                          Allows management of up to {selectedPlan?.assetCount} assets.
                        </span>
                      </li>
                      <li className='d-flex mb-3'>
                        <i className='fa-solid fa-check'></i>
                        <span className='ms-3'>Includes import/export feature.</span>
                      </li>
                      <li className='d-flex mb-3'>
                        <i className='fa-solid fa-check'></i>
                        <span className='ms-3'>Unlimited history management.</span>
                      </li>
                    </ul>
                    <h3 className='fw-bolder m-0 mb-3'>
                      Billed Now: PKR{' '}
                      {selectedPlan?.isYearly
                        ? selectedPlan?.priceYearly
                        : selectedPlan?.priceMonthly}{' '}
                      / {selectedPlan?.isYearly ? 'Year' : 'Month'}
                    </h3>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
      <Loader loading={isLoading || loading} />
    </>
  )
  
}

export default CheckoutPayment
