import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'
import './Subscription.css'
import { useGetAllPlansQuery } from 'App/Modules/Services/Plans'
import Loader from 'App/Loaders/BeatLoader'
import { useEffect, useState } from 'react'
import { useGetSubscriptionQuery } from 'App/Modules/Services/Payments'
import { decrypt, encrypt } from 'config'
interface Plan {
  id: string // Adjust type based on your actual data
  name: string
  assetCount: number
  priceYearly: number
  priceMonthly: number
  isEnterprise: boolean
  description?: string // Optional, if it might not be present
}

function Subscription() {
  const location = useLocation()
  const navigate = useNavigate()
  const isPricingRoute = location.pathname === '/pricing'
  const { data: PlanList, isLoading, isError, error } = useGetAllPlansQuery({})
  const { data: ActiveSubscription, isLoading: activePlanLoading } = useGetSubscriptionQuery({})
  const [isYearlyToggle, setIsYearlyToggle] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(window.innerWidth > 991)
  const [decryptedPlans, setDecryptedPlans] = useState<Plan[]>([])

  useEffect(() => {
    if (PlanList?.plans) {
      const plans = PlanList.plans.map((plan) => {
        const decryptedPlan = JSON.parse(decrypt(plan))
        return {
          ...decryptedPlan,
        }
      })
      setDecryptedPlans(plans)
    }
  }, [PlanList])

  const handleLinkToggle = () => {
    if (window.innerWidth <= 991) {
      setMobileMenu(!mobileMenu)
    }
  }

  const handlePlanSelection = (plan: Plan) => {
    const selectedPlan = {
      ...plan,
      isYearly: isYearlyToggle,
    }

    // Encrypt the selected plan before saving it to localStorage
    const encryptedPlan = encrypt(JSON.stringify(selectedPlan))

    // Convert the encrypted plan object to a string
    localStorage.setItem('selectedPlan', JSON.stringify(encryptedPlan))
  }

  const isPlanActive = (plan) => {
    const activePlanId = ActiveSubscription?.activeSubscription?.planId
    const activeSubscriptionType = ActiveSubscription?.activeSubscription?.subscriptionType

    // Check if current plan is active and toggle states match
    if (activePlanId === plan.id) {
      return (
        (isYearlyToggle && activeSubscriptionType === 'yearly') ||
        (!isYearlyToggle && activeSubscriptionType === 'monthly')
      )
    }
    return false
  }

  return (
    <section className='subscription-section'>
      <div className='custom-container container'>
        <div className='text text-center mb-xl-5 mb-4'>
          <h2 className={`h-main mb-xl-5 mb-4 ${isPricingRoute ? 'text-black' : 'text-white'}`}>
            Subscription Plans
          </h2>{' '}
          <p className='mb-xl-5 mb-4'>
            Asset Rack’s services come with competitive price to quality ratio. We do our best to
            deliver you cost-efficient asset tracking management solutions. Pick the best suitable
            pricing plan for your business needs.
          </p>
        </div>
        <div className='d-flex align-items-center justify-content-center swich-heading'>
          <strong className='fw-bold fs-2 mb-1'>Yearly</strong>
          <div className='mx-5'>
            <label className='switch'>
              <input
                type='checkbox'
                checked={!isYearlyToggle}
                onChange={() => setIsYearlyToggle(!isYearlyToggle)}
              />
              <span className='slider round'></span>
            </label>
          </div>
          <strong className='fw-bold fs-2 mb-1'>Monthly</strong>
        </div>
        <div className='row pt-5 justify-content-center'>
          {decryptedPlans?.map((plan) => (
            <div key={plan.id} className='col-xl-2 col-lg-4 col-sm-6 mb-lg-3 mb-md-4 mb-3'>
              <div className={`box text-center ${plan?.name === 'Free Plan' ? 'active' : ''}`}>
                <div className='box-head'>
                  <div className='text mb-xxl-5 mb-2'>
                    {!plan?.isEnterprise ? (
                      <div>
                        <p>Upto</p>
                        <strong className='h-main'>{plan?.assetCount}</strong>
                        <p className='mb-0'>Assets</p>
                      </div>
                    ) : (
                      <div>
                        <strong className='h-main'>{plan?.description}</strong>
                      </div>
                    )}
                  </div>
                </div>
                <div className='box-body'>
                  <p className='mb-xl-5 mb-4'>Subscription Plan</p>
                  <span className='d-block mb-xxl-5 mb-2 fw-bold p-mini'>{plan?.name}</span>
                  <div className='price mb-xxl-5 mb-2'>
                    <strong>
                      {plan.priceYearly === 0 && !plan?.isEnterprise ? (
                        <span>Free</span>
                      ) : !plan?.isEnterprise ? (
                        <>
                          <span>PKR {isYearlyToggle ? plan?.priceYearly : plan?.priceMonthly}</span>{' '}
                          / <span>{isYearlyToggle ? 'Year' : 'Month'}</span>
                        </>
                      ) : null}
                    </strong>
                  </div>
                  {plan.priceYearly === 0 && !plan?.isEnterprise ? (
                    ''
                  ) : plan?.isEnterprise ? (
                    <ScrollLink
                      to='contact'
                      smooth={true}
                      duration={500}
                      onClick={handleLinkToggle}
                      className='main-btn main-round-btn d-inline-block'
                    >
                      Contact
                    </ScrollLink>
                  ) : (
                    <button
                      className={
                        isPlanActive(plan)
                          ? 'main-btn main-round-btn-plans d-inline-block'
                          : `main-btn main-round-btn d-inline-block ${isPlanActive(plan) ? 'disabled' : ''}`
                      }
                      onClick={(e) => {
                        if (isPlanActive(plan)) {
                          e.preventDefault()
                        } else {
                          handlePlanSelection(plan)
                          navigate('/payment')
                        }
                      }}
                    >
                      {isPlanActive(plan) ? 'Subscribed' : 'Subscribe'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Loader loading={isLoading && activePlanLoading} />
    </section>
  )
}

export default Subscription
