import Header from 'App/Modules/Home/Header/Header'
import Footer from 'App/Modules/Home/Footer/Footer'

export function TermsService() {
  return (
    <>
      <Header />
      <div className='terms-Conditions'>
        <div className='container'>
          <div className='main-terms-Conditions'>
            <h1 className='text-center'>Terms of Service - Asset Rack</h1>
            <div className='terms-introduction'>
              <h2>Introduction</h2>
              <p>
                By using Asset Rack ("the Service"), you agree to the following terms and conditions
                ("Terms of Service"). Asset Rack is owned and operated by SmartSign, LLC ("the
                Company"), located in Brooklyn, New York. The Company reserves the right to update
                and modify these Terms of Service without prior notice. Violation of any terms may
                result in the termination of your account.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Purpose of the Service</h2>
              <p>
                The Service is designed to help you manage and maintain an inventory of assets, such
                as business assets, personal property, insurance policies, and warranties. The
                Service does not provide legal, tax, or financial advice, nor does it serve as proof
                of ownership or possession of assets. We recommend consulting with legal,
                accounting, or financial professionals before making decisions based on the
                information provided by the Service.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Use of the Service</h2>
              <p className='mb-3'>
                You agree to use the Service solely for your personal or internal business purposes.
                Your access to the Service is non-transferable and intended only for you. It is your
                responsibility to keep your registration and account information accurate. The
                Service may not function correctly if your account information is outdated.
              </p>
              <p className='mb-3'>
                By providing feedback or suggestions, you grant the Company a perpetual,
                royalty-free license to use them for any purpose, including improving the Service
                and creating marketing materials. You understand that the technical processing and
                transmission of the Service, including your content, may involve unencrypted
                transfers over various networks and changes to conform to technical requirements.
              </p>
              <p className='mb-3'>
                When you upload content to the Service, you authorize the Company to display your
                company name and logo on its website and in marketing materials at no charge. The
                Company does not claim ownership of other content you upload to the Service.{' '}
              </p>
              <p>
                You are responsible for providing the necessary equipment and software to connect to
                the Service and for any fees incurred, such as internet or mobile data charges.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Prohibited Actions</h2>
              <p>You agree not to:</p>
              <ul>
                <li>
                  <p>Upload content that violates applicable laws.</p>
                </li>
                <li>
                  <p>
                    Use automated data-gathering tools, such as robots or spiders, without the
                    Company’s written consent.
                  </p>
                </li>
                <li>
                  <p>Interfere with the Service's operation by uploading malware.</p>
                </li>
                <li>
                  <p>Send unsolicited bulk messages ("spam").</p>
                </li>
                <li>
                  <p>Upload content that is defamatory, harassing, obscene, or pornographic.</p>
                </li>
                <li>
                  <p>Reverse-engineer, decompile, or disassemble the Service’s software.</p>
                </li>
                <li>
                  <p>
                    Take actions that impose an unreasonable load on the Service’s infrastructure.
                  </p>
                </li>
                <li>
                  <p>
                    Upload content that infringes on third-party intellectual property or privacy
                    rights.
                  </p>
                </li>
                <li>
                  <p>Upload health information that violates the HIPAA Privacy Rule.</p>
                </li>
              </ul>
            </div>
            <div className='terms-introduction'>
              <h2>User Accounts</h2>
              <p className='mb-3'>
                To create an account, you must provide your company’s name, a valid email address,
                and your name. Accounts must be created by humans, not automated means such as bots.
                You are responsible for securing your account and password. The Company is not
                liable for any loss or damage from your failure to secure your account. Notify the
                Company immediately if you suspect unauthorized use of your account.
              </p>
              <p>
                The Service is not intended for users under 18. By using the Service, you confirm
                that you are at least 18 years old.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>User Content</h2>
              <p>
                You are responsible for all content posted under your account and any activities
                that occur under your account. While the Company does not pre-screen content, it
                reserves the right to remove or refuse any content at its discretion.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Third-Party Accounts</h2>
              <p>
                The Service may allow you to log in using third-party accounts, such as Facebook or
                LinkedIn. By providing your third-party credentials, you authorize the Company to
                access and use those accounts as your agent. The Company will not share your
                personal information with third parties except as necessary to verify your identity.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Links to Other Websites</h2>
              <p>
                The Service may include links to third-party websites. The Company is not
                responsible for the content of these websites, and a link does not imply
                endorsement. Any content you post on the Company’s social media accounts is subject
                to the respective platform’s terms of service and privacy policies.{' '}
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Intellectual Property Rights</h2>
              <p>
                All data not provided by users is the intellectual property of the Company or its
                licensors. You agree not to use this intellectual property for third-party benefits,
                to create derivative works, or in connection with any product or service without the
                Company’s written consent.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Use of Cookies</h2>
              <p>
                Cookies are small data files stored on your device. The Company uses cookies to
                enhance performance and functionality. You may refuse cookies by adjusting your
                browser settings, but this may limit your use of the Service. By using the Service,
                you consent to the use of cookies and the data they collect.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Disclaimer of Warranties; Limitation of Liability</h2>
              <p>The Company does not guarantee that:</p>
              <ul>
                <li>
                  <p>The Service will meet your requirements.</p>
                </li>
                <li>
                  <p>The Service will be uninterrupted, secure, or error-free.</p>
                </li>
                <li>
                  <p>Results from the Service will be accurate or reliable.</p>
                </li>
                <li>
                  <p>
                    The quality of products, services, or information obtained through the Service
                    will meet your expectations.
                  </p>
                </li>
                <li>
                  <p>Any errors in the Service will be corrected.</p>
                </li>
              </ul>
              <p>
                You agree that the Company is not liable for any direct, indirect, incidental,
                special, consequential, or exemplary damages, including but not limited to loss of
                profits, goodwill, data, or other intangible losses. The Company’s aggregate
                liability to you will not exceed one hundred dollars or the cost of services
                provided, whichever is less.
              </p>
              <p>
                The Service is not intended as a storage service, and the Company may limit the time
                uploaded content is available.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Intellectual Property Complaints</h2>
              <p>
                If you believe your intellectual property rights have been infringed, contact the
                Company’s designated agent with the following information:
              </p>
              <ul>
                <li>
                  <p>A signature of the authorized person acting on behalf of the owner.</p>
                </li>
                <li>
                  <p>
                    Identification of the copyrighted work or intellectual property right claimed to
                    be infringed.
                  </p>
                </li>
                <li>
                  <p>
                    Identification of the material claimed to be infringing and its location on the
                    Service.
                  </p>
                </li>
                <li>
                  <p>Your contact information.</p>
                </li>
                <li>
                  <p>
                    A statement of good faith belief that the use of the material is unauthorized.
                  </p>
                </li>
                <li>
                  <p>
                    A statement under penalty of perjury that the information provided is accurate.
                  </p>
                </li>
              </ul>
              <p>
                Contact the Company’s designated agent at customerservice@assetrack.com for
                complaints. Misrepresentations of infringement claims may result in liability for
                damages.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Assignability</h2>
              <p>
                The Company may assign its rights under these Terms of Service to an affiliated
                company or in the event of a merger or acquisition. You will be notified of any such
                assignment by an update to this page.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Termination</h2>
              <p>
                These Terms of Service apply from your first use of the Service until your account
                is terminated or the Service is discontinued. You are responsible for canceling your
                account properly. Upon cancellation, your content will be inaccessible and deleted
                within 30 days. The Company may suspend or cancel your account for any reason at any
                time.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Privacy Policy</h2>
              <p>
                Data provided to the Service is subject to the Asset Rack Privacy Policy. The
                Company reserves the right to update the Privacy Policy at any time.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>Disputes</h2>
              <p>
                These Terms of Service are governed by New York State law. Any disputes will be
                resolved in Kings County, New York. Claims arising from these Terms will be settled
                by arbitration administered by the American Arbitration Association, with the
                hearing before a single arbitrator. Preliminary injunctive relief may be sought in a
                court of jurisdiction.
              </p>
              <p>
                You agree to indemnify the Company against any claims or expenses resulting from
                your breach of these Terms or your use of the Service.
              </p>
            </div>
            <div className='terms-introduction'>
              <h2>General Provisions</h2>
              <p>
                Failure to enforce any right or provision of these Terms does not constitute a
                waiver. These Terms represent the entire agreement between you and the Company
                and supersede any prior agreements. If any provision is found unenforceable, the
                remaining provisions will remain in effect.
              </p>
              <p>
                In the event of a conflict between these Terms and other agreements with the
                Company, these Terms govern your use of the Service.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
