import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import { Images } from 'Template/assets/Assets'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Features.css'

function Features() {
  return (
    <section className='features-section' id='features'>
      <div className='custom-container container'>
        <div className='text text-center mb-xl-5 mb-4'>
          <h2 className='h-main mb-xl-5 mb-4'>Key Features of Asset Rack</h2>
          <p>Powerful characteristics for seamless and effective asset management.</p>
        </div>
        <div className='custom-tabs'>
          <Tab.Container id='left-tabs-example' defaultActiveKey='1'>
            <Nav variant='pills'>
              <Nav.Item>
                <Nav.Link eventKey='1'>Unlimited Users</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='2'>Unlimited Custom Reports</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='3'>Configurable Email Alerts</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='4'>Barcode Scanning</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='5'>Maintenance Scheduling</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='6'>Complete Check-in and Check-Out Features:</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey='1'>
                <Row className='align-items-center'>
                  <Col lg='7'>
                    <div className='text pe-4'>
                      <h2 className='h-main mb-xl-5 mb-4'>Unlimited Users</h2>
                      <p>
                        Asset Rack's flexibility extends to user management, offering unlimited user
                        accounts with tailored access levels. Whether you're a small team or a large
                        organization, Asset Rack accommodates all users without additional charges.
                        Each user can be assigned a unique level of access, from basic viewing
                        permissions to full administrative control, ensuring that everyone in your
                        organization can utilize the platform according to their responsibilities.
                        This inclusivity fosters collaboration and efficiency, making Asset Rack a
                        convenient solution for organizations of all sizes.
                      </p>
                    </div>
                  </Col>
                  <Col lg='5'>
                    <div className='image ps-lg-3 mt-lg-0 mt-3'>
                      <img src={Images.Feature_1} alt='features' className='img-fluid' />
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey='2'>
                <Row className='align-items-center'>
                  <Col lg='7'>
                    <div className='text pe-4'>
                      <h2 className='h-main mb-xl-5 mb-4'>Unlimited Custom Reports</h2>
                      <p>
                        With Asset Rack, reporting is not just comprehensive but also limitless.
                        Tailor your reports to meet your organization's specific needs,
                        incorporating various parameters such as asset status, depreciation,
                        maintenance schedules, and more. Asset Rack's intuitive reporting tools
                        empower users to generate insightful reports that provide actionable
                        insights into asset management. Whether you need to analyze asset
                        performance, track maintenance activities, or monitor asset depreciation,
                        Asset Rack's unlimited custom reporting feature allows you to save time and
                        streamline your workload by creating and saving reports that suit your
                        requirements.
                      </p>
                    </div>
                  </Col>
                  <Col lg='5'>
                    <div className='image ps-lg-3 mt-lg-0 mt-3'>
                      <img src={Images.Feature_1} alt='features' className='img-fluid' />
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey='3'>
                <Row className='align-items-center'>
                  <Col lg='7'>
                    <div className='text pe-4'>
                      <h2 className='h-main mb-xl-5 mb-4'>Configurable Email Alerts:</h2>
                      <p>
                        Asset Rack keeps you informed and proactive with its configurable email
                        alerts feature. Set up alerts for important events such as upcoming
                        maintenance schedules, expiring contracts, or overdue assets, ensuring that
                        you never miss a critical deadline. Customize alerts to match your
                        organization's workflow and priorities, empowering you to stay ahead of
                        potential issues and maintain optimal asset performance. By receiving timely
                        notifications, you can take immediate action to address issues, minimize
                        risks, and ensure smooth asset management operations.
                      </p>
                    </div>
                  </Col>
                  <Col lg='5'>
                    <div className='image ps-lg-3 mt-lg-0 mt-3'>
                      <img src={Images.Feature_1} alt='features' className='img-fluid' />
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey='4'>
                <Row className='align-items-center'>
                  <Col lg='7'>
                    <div className='text pe-4'>
                      <h2 className='h-main mb-xl-5 mb-4'>Barcode Scanning</h2>
                      <p>
                        Asset tracking becomes effortless with Asset Rack's mobile barcode scanning
                        functionality. Conduct internal audits and update asset information directly
                        from the field by scanning asset barcodes with ease. This streamlined
                        process eliminates manual data entry errors and ensures accurate and
                        real-time asset tracking. Asset Rack's barcode scanning feature enhances
                        efficiency and accuracy, allowing you to maintain precise records of asset
                        movements and locations, ultimately improving overall asset management
                        effectiveness.
                      </p>
                    </div>
                  </Col>
                  <Col lg='5'>
                    <div className='image ps-lg-3 mt-lg-0 mt-3'>
                      <img src={Images.Feature_1} alt='features' className='img-fluid' />
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey='5'>
                <Row className='align-items-center'>
                  <Col lg='7'>
                    <div className='text pe-4'>
                      <h2 className='h-main mb-xl-5 mb-4'>Maintenance Scheduling</h2>
                      <p>
                        Asset Rack simplifies asset maintenance with its intuitive scheduling
                        feature. Create customized maintenance schedules tailored to your assets'
                        specific needs and your organization's operational requirements. By
                        establishing routine maintenance tasks, you can prolong asset lifespan,
                        minimize downtime, and ensure optimal performance. Asset Rack's maintenance
                        scheduling feature allows you to stay ahead of maintenance activities by
                        setting reminders and alerts for upcoming tasks. With comprehensive
                        monitoring capabilities, you can track maintenance history and ensure that
                        all necessary tasks are completed on time, enhancing asset reliability and
                        reducing costly repairs.
                      </p>
                    </div>
                  </Col>
                  <Col lg='5'>
                    <div className='image ps-lg-3 mt-lg-0 mt-3'>
                      <img src={Images.Feature_1} alt='features' className='img-fluid' />
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey='6'>
                <div className='row align-items-center'>
                  <div className='col-lg-7'>
                    <div className='text pe-4'>
                      <h2 className='h-main mb-xl-5 mb-4'>
                        Complete Check-in and Check-Out Features:
                      </h2>
                      <p>
                        Asset Rack offers comprehensive check-in and check-out functionality to
                        monitor asset movements within your organization effectively. Track the
                        transfer of assets between employees or departments with ease, recording all
                        transactions in the asset's Events tab for full visibility. Generate
                        detailed check-out reports to analyze employee interactions, due dates, and
                        overdue assets, facilitating accountability and compliance. Asset Rack's
                        complete check-in and check-out features streamline asset tracking
                        processes, enabling you to maintain accurate records and ensure proper asset
                        utilization throughout its lifecycle.
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-5'>
                    <div className='image ps-lg-3 mt-lg-0 mt-3'>
                      <img src={Images.Feature_1} alt='features' className='img-fluid' />
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </section>
  )
}

export default Features
