import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { useAddDocumentMutation } from 'App/Modules/Services/Documents'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import { multiSpace } from 'config'

interface NewAuditModalProps {
  uploadDocumentModal: boolean
  handleClose: () => void
  assetId: any
}

const UploadDocumentModal: React.FC<NewAuditModalProps> = ({
  uploadDocumentModal,
  handleClose,
  assetId,
}) => {
  const { saveAuth, setCurrentUser } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [fileError, setFileError] = useState<string | null>(null)
  const [descriptionError, setDescriptionError] = useState<string | null>(null)
  const [addDocument] = useAddDocumentMutation()

  const fileSizeLimit = 10 * 1024 * 1024
  const supportedTypes = [
    'jpg',
    'jpeg',
    'png',
    'bmp',
    'rtf',
    'gif',
    'doc',
    'pdf',
    'docx',
    'ppt',
    'pptx',
    'xls',
    'xlsx',
    'txt',
    'msg',
  ]

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value?.length > 0 && event.target.value?.length <= 250) {
      setDescription(event.target.value)
      setDescriptionError(null)
    } else if (event.target.value?.length > 250) {
      setDescriptionError('Description should not exceed 250 characters')
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      const fileExtension = file.name.split('.').pop()?.toLowerCase()

      if (!fileExtension || !supportedTypes.includes(fileExtension)) {
        setFileError('Unsupported file type')
        setSelectedFile(null)
        return
      }

      if (file.size > fileSizeLimit) {
        setFileError('File upload failed. Please ensure the file size does not exceed 10 MB')
        setSelectedFile(null)
      } else {
        setFileError(null)
        setSelectedFile(file)
      }
    }
  }

  const handleUpload = async () => {
    if (!description?.trim()) {
      setDescriptionError('Description is required')
      return
    }

    if (!selectedFile) {
      setFileError('Please select a valid file')
      return
    }

    const formData = new FormData()
    formData.append('assetId', assetId)
    formData.append('description', description?.trim().replace(multiSpace, ' '))
    formData.append('document', selectedFile)

    try {
      setLoading(true)
      const result: any = await addDocument(formData)
      if (result?.data?.success) {
        handleClose()
        toast.success(result?.data?.message)
        setLoading(false)
      } else {
        toast.error(`Upload failed : ${result?.error?.data?.message}`)
        setLoading(false)
        checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return (
    <Modal show={uploadDocumentModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Upload Document To Asset</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='formText'>
            <Form.Label className='required-label'>Description</Form.Label>
            <Form.Control
              type='text'
              value={description}
              onChange={handleDescriptionChange}
              isInvalid={!!descriptionError}
            />
            <Form.Control.Feedback type='invalid'>{descriptionError}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formFile'>
            <Form.Label className='required-label'>Document</Form.Label>
            <InputGroup>
              <Form.Control type='file' onChange={handleFileChange} isInvalid={!!fileError} />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-file fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
            {fileError && <p className='text-danger'>{fileError}</p>}
          </Form.Group>
          <p className='p-0 m-0'>
            Only ({supportedTypes.map((type) => `${type}`).join(', ')}) are allowed
          </p>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn cancel-btn border' onClick={handleClose}>
          Cancel
        </button>
        <button
          className='btn btn-primary'
          onClick={handleUpload}
          disabled={!selectedFile || descriptionError ? true : false || fileError ? true : false}
        >
          <i className='fa fa-upload fs-2 text-light me-1' />
          Upload
        </button>
      </Modal.Footer>
      <Loader loading={loading} />
    </Modal>
  )
}

export default UploadDocumentModal
