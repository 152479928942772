import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const accountDetailsApi = createApi({
  reducerPath: 'accountDetailsApi',
  baseQuery: customFetchBaseQuery({ path: 'user' }),
  tagTypes: ['AccountDetails'],
  endpoints: (build) => ({
    getAccountDetails: build.query<void, void>({
      query: () => ({
        url: `/account-details`,
        method: 'GET',
      }),
      providesTags: ['AccountDetails'],
    }),
    getBankDetails: build.query<void, void>({
      query: () => ({
        url: `/bank-details`,
        method: 'GET',
      }),
      providesTags: ['AccountDetails'],
    }),
    getBillingDetails: build.query<void, void>({
      query: () => ({
        url: `/billing-details`,
        method: 'GET',
      }),
      providesTags: ['AccountDetails'],
    }),
    bankDetails: build.mutation<any, any>({
      query: (body) => ({
        url: '/bank',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'AccountDetails' }],
    }),
    billingDetails: build.mutation<any, any>({
      query: (body) => ({
        url: '/billing',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'AccountDetails' }],
    }),
  }),
})

export const {
  useGetAccountDetailsQuery,
  useGetBankDetailsQuery,
  useGetBillingDetailsQuery,
  useBankDetailsMutation,
  useBillingDetailsMutation,
} = accountDetailsApi
