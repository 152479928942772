import { decrypt } from 'config'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Images } from 'Template/assets/Assets'
import { useDownloadInvoiceMutation, useGetInvoiceQuery } from '../Services/Payments'
import Loader from 'App/Loaders/BeatLoader'
interface Plan {
  name: string
  assetCount: number
  isImportExport: boolean
  isHistoryUnlimited: boolean
  isHistory: number
  priceYearly: number
  priceMonthly: number
}

interface Details {
  firstName: string
  lastName: string
  email: string
  street: string
  floor: string
  phoneNumber: string
  country: string
  state: string
  apartment: string
}

const Invoice = () => {
  const { id } = useParams()
  const { data, isSuccess, isLoading }: any = useGetInvoiceQuery(id)
  const [downloadData, { isLoading: isLoadingDownload }] = useDownloadInvoiceMutation()
  const [details, setDetails] = useState<Details | null>(null)

  useEffect(() => {
    if (data) {
      const decryptedDetails = {
        firstName: data?.billingDetails?.firstName ? decrypt(data?.billingDetails?.firstName) : '',
        lastName: data?.billingDetails?.lastName ? decrypt(data?.billingDetails?.lastName) : '',
        email: data?.billingDetails?.email ? decrypt(data?.billingDetails?.email) : '',
        street: data?.billingDetails?.street ? decrypt(data?.billingDetails?.street) : '',
        floor: data?.billingDetails?.floor ? decrypt(data?.billingDetails?.floor) : '',
        phoneNumber: data?.billingDetails?.phoneNumber
          ? decrypt(data?.billingDetails?.phoneNumber)
          : '',
        country: data?.billingDetails?.country ? decrypt(data?.billingDetails?.country) : '',
        state: data?.billingDetails?.state ? decrypt(data?.billingDetails?.state) : '',
        apartment: data?.billingDetails?.apartment ? decrypt(data?.billingDetails?.apartment) : '',
      }
      setDetails(decryptedDetails)
    }
  }, [data])

  const downloadInvoice = async () => {
    if (id) {
      const response = await downloadData({ invoiceId: id })
      if (response?.data?.success) {
        const link = document.createElement('a')
        link.href = `${process.env.REACT_APP_INVOICE_URL}/${response?.data?.downloadUrl}`
        link.target = '_blank'
        link.setAttribute('download', `invoice-${data?.billingDetails?.plan.name}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  if (isLoading || !details) {
    return <Loader loading={true} />
  }
  return (
    <>
      <div className='invoice-container'>
        <div className='container'>
          <div className='billing-info'>
            <div className='card'>
              <div className='card-header border-0 bg-blue'>
                <div className='card-title m-0'>
                  <img
                    alt='Logo'
                    src={Images.DefaultWhiteLogo}
                    className='h-25px h-xl-30px w-100'
                  />
                </div>
                <button className='download-button' type='button' onClick={() => downloadInvoice()}>
                  <i className='fa-solid fa-download text-white fs-1'></i>
                </button>
              </div>
              <div className='card-footer'>
                <div className='d-flex align-items-center'>
                  <p>
                    {details?.street} {details?.apartment || ''} {details?.floor}, {details?.state}{' '}
                    {details?.country}
                  </p>
                </div>
                <div className='invoice-heading d-flex align-items-center'>
                  <strong className='me-2'>Phone:</strong>
                  <span>{details?.phoneNumber}</span>
                </div>
                <div className='invoice-heading d-flex align-items-center'>
                  <strong className='me-2'>Website:</strong>
                  <span>Assets Rack</span>
                </div>
                <div className='invoice-heading d-flex align-items-center'>
                  <strong className='me-2'>Email:</strong>
                  <span>{details?.email}</span>
                </div>
                <div className='invoice-heading d-flex align-items-center'>
                  <strong className='me-2'>Payment Date:</strong>
                  <span>{details?.phoneNumber}</span>
                </div>
                <div className='main-invoice'>
                  <div>
                    <div className='invoice-heading d-flex align-items-center mb-3'>
                      <strong className='me-2'>Bill To:</strong>
                      <span></span>
                    </div>
                    <div className='invoice-heading d-flex align-items-center mb-3'>
                      <strong className='me-2'>Customer Name:</strong>
                      <span>
                        {details?.firstName} {details?.lastName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='main-invoice'>
                  <div className='invoice-heading d-flex align-items-center mb-3'>
                    <strong className='me-2'>CHARGE DETAIL:</strong>
                    <span>{data?.billingDetails?.subscriptionType}</span>
                  </div>
                </div>
                <div className='table-responsive'>
                  <table className='invoice-table table-bordered mb-5'>
                    <thead>
                      <tr>
                        <th>Plan Name</th>
                        <th>Asset Counts</th>
                        <th>Import/Export</th>
                        <th>History</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data?.billingDetails?.plan.name}</td>
                        <td>{data?.billingDetails?.plan.assetCount}</td>
                        <td>
                          {data?.billingDetails?.plan.isImportExport ? 'Supported' : 'Unsupported'}
                        </td>
                        <td>
                          {data?.billingDetails?.plan.isHistoryUnlimited
                            ? 'Unlimited'
                            : `${data?.billingDetails?.plan.isHistory} days`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='total d-flex justify-content-end align-items-center'>
                  <strong>
                    Total: PKR
                    {data?.billingDetails?.subscriptionType === 'yearly'
                      ? data?.billingDetails?.plan?.priceYearly
                      : data?.billingDetails?.plan?.priceMonthly}
                  </strong>
                </div>
                <div className='bottom-heading-invoice d-flex justify-content-center'>
                  Thank you!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={isLoadingDownload} />
    </>
  )
}

export default Invoice
