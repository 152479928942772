import { useSellAssetMutation } from 'App/Modules/Services/AssetActions'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getCurrentDate, priceRegex } from 'config'
import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface NewAuditModalProps {
  sellModal: boolean
  handleClose: () => void
  refetchAssetDetails: () => void
  assetId: any
  setRefreshHistory: any
  currencyObject: any
}

const validationSchema = Yup.object().shape({
  saleDate: Yup.string().required('Sale Date is required.'),
  soldTo: Yup.string().max(20, 'Sold To field must be exactly 20 characters in length').nullable(),
  saleAmount: Yup.string().matches(priceRegex, 'Sale Amount must be up to 15 digits').nullable(),
  notes: Yup.string().max(250, 'Notes must be at most 250 characters'),
})

const SellModal: React.FC<NewAuditModalProps> = ({
  sellModal,
  handleClose,
  assetId,
  refetchAssetDetails,
  setRefreshHistory,
  currencyObject,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [sellStatus] = useSellAssetMutation()
  const currentDate = getCurrentDate()
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues: {
      assetId,
      saleDate: getCurrentDate(),
      soldTo: '',
      saleAmount: '',
      notes: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const processedValues = {
        ...values,
        saleAmount: values.saleAmount || null,
      }
      try {
        setLoading(true)
        const result: any = await sellStatus(processedValues)
        if (result?.data?.success) {
          refetchAssetDetails()
          setRefreshHistory((prev) => !prev)
          toast.success(result?.data?.message)
          handleClose()
          setLoading(false)
        } else {
          toast.error('Failed to update asset repair status')
          checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
        }
      } catch (error: any) {
        toast.error('Failed to update asset sell status')
      }
    },
  })

  const splitName = currencyObject?.name.split(' - ')
  const currencyName = splitName && splitName[1]
  const currencySymbol = splitName && splitName[2]

  return (
    <Modal show={sellModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Sell Asset</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label className='required-label'>Sale Date</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='saleDate'
                max={currentDate}
                value={formik.values.saleDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.saleDate && formik.touched.saleDate} // Highlight the field if there's an error
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
            {formik.errors.saleDate && formik.touched.saleDate && (
              <div className='text-danger'>{formik.errors.saleDate}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formSoldTo'>
            <Form.Label>Sold To</Form.Label>
            <Form.Control
              type='text'
              name='soldTo'
              value={formik.values.soldTo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.soldTo && formik.touched.soldTo && (
              <div className='text-danger'>{formik.errors.soldTo}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formSaleAmount'>
            <Form.Label>Sale Amount</Form.Label>
            <InputGroup>
              <InputGroup.Text className='bg-primary text-light'>{currencySymbol}</InputGroup.Text>
              <Form.Control
                type='text'
                name='saleAmount'
                value={formik.values.saleAmount}
                onChange={(e) => {
                  const value = e.target.value
                  if (/^\d*$/.test(value)) {
                    formik.handleChange(e)
                  }
                }}
                onBlur={formik.handleBlur}
                placeholder={currencyName}
              />
            </InputGroup>
            {formik.errors.saleAmount && formik.touched.saleAmount && (
              <div className='text-danger'>{formik.errors.saleAmount}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formNotes'>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              name='notes'
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.notes && formik.touched.notes && (
              <div className='text-danger'>{formik.errors.notes}</div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
          <button type='submit' className='btn btn-primary' disabled={!formik?.values?.saleDate}>
            Sell
          </button>
        </Modal.Footer>
      </Form>
      <Loader loading={loading} />
    </Modal>
  )
}

export default SellModal
