import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { UsersHeader } from './UsersHeader'
import ConfirmationPopup from '../../../../../../Template/helpers/components/ConfirmationPopup'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import { PageTitle } from 'Template/layout/core'
import { useGetSubUsersQuery, useSendEmailSubUserMutation, useDeleteSubUserMutation,} from 'App/Modules/Services/SubUser'
import { UserResponse } from '../Core/Model'
import SplashScreen from 'App/Loaders/SplashScreen'
import Loader from 'App/Loaders/BeatLoader'
import { Link } from 'react-router-dom'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

const UsersListing = () => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const currentUserId = currentUser?.id
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const [check, setCheck] = useState<number[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [data, setData] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const [skip, setSkip] = useState<boolean>(false)
  const [selected, setSelected] = useState<number[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [selectAllData, setSelectAllData] = useState<boolean>(false)
  const [deleteSubUser] = useDeleteSubUserMutation()
  const [isSitesExist, setIsSitesExist] = useState<any>(false)
  const [deleteCascade, setDeleteCascade] = useState(false)
  const [pagination, setPagination] = useState<any>({})
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const { data: userListData, isLoading: isUserDataLoding, refetch } = useGetSubUsersQuery({
    limit,
    page,
  })
  const [sendEmailMutation] = useSendEmailSubUserMutation()
  const sendEmail = async (id: number) => {
    const userType = 2
    try {
      const data = await sendEmailMutation({ id, userType }).unwrap()
      refetch()
      toast.success(data?.message)
    } catch (error: any) {
      if (!error?.data?.success) {
        refetch()
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
    }
  }

  const handleShow = (item: UserResponse) => {
    setData(item)
    setShow(true)
  }

  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }
  const checkVistingPage = (dataList: any) => {
    if (isSitesExist === false) {
      if (dataList && dataList?.length > 0) {
        setIsSitesExist(dataList?.length)
      } else {
        setIsSitesExist(0)
      }
    }
  }
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = userListData?.sites?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
    } else {
      setSelected([])
      setSelectAll(false)
    }
  }

  const deleteSelectedSubUser = async (id: number[]) => {
    try {
      const subUserIds = { subUserIds: id }
      const res = await deleteSubUser({ cascade: deleteCascade ? 1 : 0, id }).unwrap()
      toast.success(res.message)
      setCheck([])
      setSelectAll(false)
      setDeleteModal(false)
      setDeleteCascade(false)
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
    }
  }

  const cancel = () => {
    setDeleteModal(false)
    setDeleteCascade(false)
  }
  const confirm = () => deleteSelectedSubUser(check)

  useEffect(() => {
    checkVistingPage(userListData?.users)
    setPagination(userListData?.pagination)
    setLoading(false)
  }, [userListData])

  useEffect(() => {
    if (selectAllData) {
      const newSelecteds = userListData?.users?.map((item: any) => item.id) || []
      setSelected(newSelecteds)
    }
    setSelectAllData(false)
  }, [userListData])

  useEffect(() => {
    if (userListData?.users.length > 0) {
      setPagination(userListData?.pagination)
      setSkip(true)
    }
  }, [userListData])

  const handlePageChange = (currentPage: any) => {
    setLoading(true)
    setPage(currentPage)
    setSkip(false)
  }
  const currentPage = pagination?.page || 0
  const totalRecords = pagination?.total || 0
  const pageSize = limit || 10
  const startRecord = (currentPage - 1) * pageSize + 1
  const endRecord = Math.min(currentPage * pageSize, totalRecords)

  return (
    <>
      <SplashScreen isLoadingTemplate={isUserDataLoding} />
      <PageTitle breadcrumbs={[]}>Users</PageTitle>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className='container'>
            <div className='card'>
              <ConfirmationPopup
                deleteModal={deleteModal}
                cancel={cancel}
                confirm={confirm}
                deleteCascade={deleteCascade}
              />
              <UsersHeader
                setDeleteModal={setDeleteModal}
                setCheck={setCheck}
                handleShow={handleShow}
                permission={permission}
                staffUser={staffUser}
              />
              <div className='card-body py-4'>
                <p className={'text-dark'}>
                  Create new users who will be able to access your <strong>Asset Rack</strong>{' '}
                  system. You can decide each user's privileges and what they can and can't do
                  within your account.
                </p>
                <div className='d-flex limit-options align-items-center mb-3'>
                  <span className='text-muted'>Showing</span>
                  <select
                    className='form-select form-select-sm fw-bold mx-3 border-1'
                    onChange={(e) => {
                      setLoading(true)
                      setLimit(Number(e.target.value))
                      setPage(Number(1))
                    }}
                    value={limit}
                  >
                    <option value='10'>10</option>
                    <option value='15'>15</option>
                    <option value='20'>20</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                  </select>
                  <span className='text-muted'>entries</span>
                </div>
                <div className='table-responsive pb-4 custom-table'>
                  <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    role='table'
                  >
                    <thead>
                      {userListData?.users?.map(() => {})}
                      <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        <th role='columnheader'> Name </th>
                        <th role='columnheader'> Group Name </th>
                        <th role='columnheader'> Job Title </th>
                        <th role='columnheader'> Email </th>
                        <th role='columnheader'> Phone </th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                      {userListData?.users?.length ? (
                        userListData?.users?.map((item: UserResponse, idx: number) => {
                          return (
                            <tr key={idx} role='row'>
                              <td role='cell' className='table-elipses listing-table-elipses'>
                                {(!permission?.updateUser || currentUserId === item?.id) &&
                                staffUser ? (
                                  <span className='d-block text-truncate'>{`${item?.firstName} ${item?.lastName}`}</span>
                                ) : (
                                  <Link
                                    to={`/users/edit/${item?.id}`}
                                    className='d-block text-truncate'
                                  >{`${item?.firstName} ${item?.lastName}`}</Link>
                                )}
                                {!item?.isEmailVerified ? (
                                  <button
                                    className='btn bg-success fw-bold text-white px-3 py-2 fs-8'
                                    onClick={() => sendEmail(item?.id)}
                                  >
                                    {'Send Set Password Email'}
                                  </button>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td role='cell' className='table-elipses listing-table-elipses'>
                                <span>{item?.SecurityGroup?.name}</span>
                              </td>
                              <td role='cell' className='table-elipses listing-table-elipses'>
                                <span>{item?.title}</span>
                              </td>
                              <td role='cell' className='table-elipses listing-table-elipses'>
                                <span>{item?.email}</span>
                              </td>
                              <td role='cell' className='table-elipses listing-table-elipses'>
                                <span>{item?.phone}</span>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={12}>
                            <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                              No records found
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {userListData?.users?.length ? (
                  <div className='d-sm-flex align-items-center justify-content-between flex-wrap pt-3'>
                    <div className='m-1'>
                      Showing {startRecord} to {endRecord} of {totalRecords} records
                    </div>
                    {pagination && (
                      <Pagination
                        className='m-1 custom-pagination'
                        defaultCurrent={1}
                        pageSize={limit}
                        current={pagination?.page}
                        total={pagination?.total}
                        onChange={handlePageChange}
                        locale={localeInfo}
                      />
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <Loader loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UsersListing
