import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useFormik } from 'formik'
import Select from 'react-select'
import { useAddReserveMutation } from 'App/Modules/Services/Reserve'
import { useGetAllPersonsQuery, useGetPersonsQuery } from 'App/Modules/Services/Persons'
import * as Yup from 'yup'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import { useGetAllCustomersQuery, useGetCustomersQuery } from 'App/Modules/Services/Customers'
import { useGetAllSitesQuery } from 'App/Modules/Services/Sites'
import { useAuth } from 'App/Modules/Auth'
import { toast } from 'react-toastify'
import {
  ReserveInterface,
  defaultReserveDetail as initialValues,
} from 'App/Pages/Reserve/Core/Model'
import PersonsModal from '../../../Persons/Components/PersonsModal'
import CustomersModal from '../../../Customers/Components/CustomersModal'
import { useParams } from 'react-router'
import { useGetAllLocationsBySiteQuery } from 'App/Modules/Services/Locations'
import DuplicateReserveModal from 'App/Pages/Reserve/Modal/DuplicateReserveModal'
import { decimalPlacesRegex, nonNumericKeys } from 'config'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

interface Props {
  show: boolean
  handleClose: () => void
  refetch: any
}

const accountDetailsSchema = Yup.object().shape({
  startDate: Yup.date()
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Start date cannot be a past date')
    .required('Start date is required'),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'End date must be after start date')
    .required('End date is required'),
  notes: Yup.string().max(250, 'Note should not exceed 250 characters'),
})

const AddReservationModal: React.FC<Props> = ({ show, handleClose, refetch }) => {
  const { id } = useParams()
  const { currentUser } = useAuth()
  const permission = currentUser?.SecurityGroup
  const staffUser = currentUser?.userType === 2
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const [siteId, setSiteId] = useState<any>()
  const [showPersonModal, setShowPersonModal] = useState<boolean>(false)
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())
  const [duplicateReserveModal, setDuplicateReserveModal] = useState(false)
  const [reserveFor, setReserveFor] = useState(1)
  const [emailEnabled, setEmailEnabled] = useState(false)
  const [firstEmail, setFirstEmail] = useState(false)
  const [secondEmail, setSecondEmail] = useState(false)
  const [reservedData, setReservedData] = useState<any>([])
  const [siteFieldError, setSiteFieldError] = useState<boolean>(false)
  const [personFieldError, setPersonFieldError] = useState<boolean>(false)
  const [selectedPersonValue, setSelectedPersonValue] = useState('')
  const [selectedCustomerValue, setSelectedCustomerValue] = useState('')
  const [personOptions, setPersonOptions] = useState([])
  const [customerOptions, setCustomerOptions] = useState([])
  const [customErrors, setCustomErrors] = useState<boolean>(false)
  const [initialPerson, setInitialPerson] = useState('')
  const [initialCustomer, setInitialCustomer] = useState('')
  const { data: siteData } = useGetAllSitesQuery({ userId: currentUser?.id })
  const { data: userPersonsList } = useGetAllPersonsQuery(
    { search: selectedPersonValue },
    { skip: !selectedPersonValue }
  )
  const { data: customerData } = useGetCustomersQuery({
    skip: personUser || customerUser || (staffUser && !permission?.viewCustomer),
  })
  const { data: customerList } = useGetAllCustomersQuery(
    { search: selectedCustomerValue },
    { skip: !selectedCustomerValue }
  )
  const { data: locationData } = useGetAllLocationsBySiteQuery(
    { userId: currentUser?.id, id: siteId },
    { skip: !siteId }
  )
  const [addReserve, { isLoading: isAdding }] = useAddReserveMutation()

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: accountDetailsSchema,
    onSubmit: async (values) => {
      try {
        await handleReserveSubmit(values)
        refetch()
      } catch (error) {
        toast.error('Something went wrong')
      }
    },
  })
  const { data: personData } = useGetPersonsQuery({
    skip: personUser || customerUser || (staffUser && !permission?.viewPerson),
  })

  useEffect(() => {
    if (personData) {
      const options = personData?.persons?.map((item) => ({
        value: item?.email,
        label: `${item?.firstName} ${item?.lastName}`,
      }))
      setPersonOptions(options)
      if (personOptions.length > 0 && options.length > personOptions.length) {
        const lastIndex = options.length - 1
        const selectedPerson = options[lastIndex]
        setSelectedPersonValue(selectedPerson.value)
        setInitialPerson(selectedPerson)
        setPersonFieldError(false)
      }
    }

    if (customerData) {
      const options = customerData?.customers?.map((item) => ({
        value: item?.email,
        label: (
          <div className='d-flex flex-column'>
            <span className='personFullName'>{item?.firstName + '' + item?.lastName}</span>
            <span className='personEmail'> {item?.email}</span>
          </div>
        ),
      }))
      setCustomerOptions(options)
      if (customerOptions.length > 0 && options.length > customerOptions.length) {
        const lastIndex = options.length - 1
        const selectedCustomer = options[lastIndex]
        setSelectedCustomerValue(selectedCustomer.value)
        setInitialCustomer(selectedCustomer)
        setCustomErrors(false)
      }
    }
  }, [personData, customerData])

  const handleReserveSubmit = async (values: ReserveInterface) => {
    switch (reserveFor) {
      case 1:
        if (!userPersonsList) {
          setPersonFieldError(true)
          return
        }
        if (!selectedPersonValue) {
          setPersonFieldError(true)
          return
        }
        setPersonFieldError(false)
        break

      case 2:
        if (!values?.siteId) {
          setSiteFieldError(true)
          return
        }
        setSiteFieldError(false)
        break

      case 3:
        if (!customerList) {
          setCustomErrors(true)
          return
        }
        if (!selectedCustomerValue) {
          setCustomErrors(true)
          return
        }
        break

      default:
        break
    }

    const data: ReserveInterface = {
      startDate: values?.startDate,
      endDate: values?.endDate,
      reserveFor: reserveFor,
      locationId: values?.locationId,
      siteId: values?.siteId,
      sendEmail: values?.sendEmail,
      reminder1: values?.reminder1,
      reminder2: values?.reminder2,
      notes: values?.notes,
      assetId: [id],
    }

    if (emailEnabled) {
      data.sendEmail = !!selectedPersonValue || !!selectedCustomerValue
    }

    switch (reserveFor) {
      case 1:
        if (selectedPersonValue) {
          data.personId = userPersonsList?.persons[0]?.id
        }
        break

      case 2:
        data.siteId = values?.siteId
        break

      case 3:
        if (selectedCustomerValue) {
          data.customerId = customerList?.customers[0]?.id
        }
        break

      default:
        break
    }

    try {
      const result = await addReserve(data).unwrap()
      if (result) {
        toast.dismiss()
        toast.success(result.message ? result.message : result.data?.message)
        setPersonFieldError(false)
        setCustomErrors(false)
        setSiteFieldError(false)
        setEmailEnabled(false)
        setSelectedPersonValue('')
        setSelectedCustomerValue('')
        formik.resetForm()
        handleClose()
      }
    } catch (error: any) {
      if (error?.data?.bookedReservation) {
        setReservedData(error?.data?.reservedAssetIds)
        handleDuplicateReserveModal()
      }
    }
  }

  const handleDuplicateReserveModal = () => {
    setDuplicateReserveModal(true)
  }
  const handleCloseDuplicateReserveModal = () => {
    setDuplicateReserveModal(false)
  }

  const handleClosePersonModal = () => {
    setShowPersonModal(false)
  }

  const handleShowPersonModal = () => {
    setShowPersonModal(true)
  }
  const handleCloseCustomerModal = () => {
    setShowCustomerModal(false)
  }

  const handleShowCustomerModal = () => {
    setShowCustomerModal(true)
  }

  const handleSelectChange = (selectedOption) => {
    setInitialPerson(selectedOption)
    setSelectedPersonValue(selectedOption?.value || '')
    if (selectedOption === null) {
      setPersonFieldError(true)
    } else {
      setPersonFieldError(false)
    }
  }

  const handleSelectChangeCustomer = (selectedOption) => {
    setInitialCustomer(selectedOption)
    setSelectedCustomerValue(selectedOption?.value || '')
    if (selectedOption === null) {
      setCustomErrors(true)
    } else {
      setCustomErrors(false)
    }
  }

  return (
    <Modal
      className='reservation-modal'
      show={show}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add a Reservation</Modal.Title>
      </Modal.Header>
      <DuplicateReserveModal
        show={duplicateReserveModal}
        reservedData={reservedData}
        handleClose={handleCloseDuplicateReserveModal}
      />
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <Modal.Body>
          <Row className='mb-3'>
            <Col xl='12'>
              <Row className='mb-6'>
                <Col lg='3'>
                  <label className=' col-form-label required fw-bold fs-6'>Schedule</label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <div className='form-group mb-3 custom-date-picker'>
                      <ReactDatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setStartDate(update[0])
                          setEndDate(update[1])
                          formik.setFieldValue(
                            'startDate',
                            moment(update[0], 'DD/MM/YYYY').toDate()
                          )
                          formik.setFieldValue('endDate', moment(update[1], 'DD/MM/YYYY').toDate())
                        }}
                        isClearable={true}
                        className='form-control'
                        placeholderText={'MM/dd/yyyy - MM/dd/yyyy'}
                      />
                    </div>
                    {formik.touched.startDate && formik.errors.startDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{String(formik.errors.startDate)}</div>
                      </div>
                    )}
                    {formik.touched.endDate && formik.errors.endDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{String(formik.errors.endDate)}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='mb-6 align-items-center'>
                <Col lg='3'>
                  <label className='col-form-label fw-bold fs-6'>Reserve for</label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <div className='d-flex'>
                    <div className='m-2 form-check-custom'>
                      <input
                        className='form-check-input custom-form-check-input'
                        {...formik.getFieldProps('reserveFor')}
                        onClick={() => {
                          setReserveFor(1)
                          formik.setFieldValue('locationId', undefined)
                          formik.setFieldValue('siteId', undefined)
                          formik.setFieldValue('customerId', undefined)
                          setSelectedCustomerValue('')
                          setSiteId(undefined)
                          setCustomErrors(false)
                          setSiteFieldError(false)
                          formik.resetForm()
                        }}
                        type='checkbox'
                        value={'person'}
                        checked={reserveFor === 1 ? true : false}
                        name={'reserveFor'}
                        id='person'
                      />
                      <label className='form-check-label fs-6 fw-bold' htmlFor='person'>
                        Person
                      </label>
                    </div>
                    <div className='m-2 form-check-custom'>
                      <input
                        className='form-check-input custom-form-check-input'
                        type='checkbox'
                        {...formik.getFieldProps('reserveFor')}
                        onClick={() => {
                          setReserveFor(2)
                          formik.setFieldValue('customerId', undefined)
                          formik.setFieldValue('reserveFor', 'site')
                          formik.setFieldValue('personId', undefined)
                          setSelectedPersonValue('')
                          setSelectedCustomerValue('')
                          setPersonFieldError(false)
                          setCustomErrors(false)
                          formik.resetForm()
                        }}
                        value={'site'}
                        checked={reserveFor === 2 ? true : false}
                        name={'reserveFor'}
                        id='location'
                      />
                      <label className='form-check-label fs-6 fw-bold' htmlFor='location'>
                        Site / Location
                      </label>
                    </div>
                    <div className='m-2 form-check-custom'>
                      <input
                        className='form-check-input custom-form-check-input'
                        type='checkbox'
                        {...formik.getFieldProps('reserveFor')}
                        onClick={() => {
                          setReserveFor(3)
                          formik.setFieldValue('locationId', undefined)
                          formik.setFieldValue('siteId', undefined)
                          formik.setFieldValue('personId', undefined)
                          setSelectedPersonValue('')
                          setSiteId(undefined)
                          setPersonFieldError(false)
                          setSiteFieldError(false)
                          formik.resetForm()
                        }}
                        value={'customer'}
                        checked={reserveFor === 3 ? true : false}
                        name={'reserveFor'}
                        id='customer'
                      />
                      <label className='form-check-label fs-6 fw-bold' htmlFor='customer'>
                        Customer
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              {reserveFor === 2 && (
                <div>
                  <Row className='mb-6'>
                    <Col lg='3'>
                      <label className='col-form-label fw-bold fs-6 required'>
                        <span>Site</span>
                      </label>
                    </Col>
                    <Col lg='9' className='fv-row'>
                      <select
                        className='form-select form-control form-control-solid form-select-solid  '
                        {...formik.getFieldProps('siteId')}
                        onChange={(e) => {
                          formik.handleChange(e)
                          const value = e.target.value
                          if (value === '') {
                            formik.setFieldValue('siteId', undefined)
                            setSiteId(0)
                            setSiteFieldError(true)
                          } else {
                            setSiteId(value)
                            setSiteFieldError(false)
                          }
                        }}
                      >
                        <option value=''>Select Site</option>
                        {siteData?.sites.map((item: any, idx: number) => {
                          return (
                            <option key={idx} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                      {siteFieldError && <span style={{ color: 'red' }}>site is required</span>}
                      {formik.touched.siteId && formik.errors.siteId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{String(formik.errors.siteId)}</div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className='mb-6'>
                    <Col lg='3'>
                      <label className='col-form-label fw-bold fs-6'>
                        <span>Location</span>
                      </label>
                    </Col>
                    <Col lg='9' className='fv-row d-flex'>
                      <select
                        className='form-select form-control form-control-solid form-select-solid  '
                        {...formik.getFieldProps('locationId')}
                        onChange={(e) => {
                          formik.handleChange(e)
                          const value = e.target.value
                          if (value === '') {
                            formik.setFieldValue('locationId', undefined)
                          }
                        }}
                      >
                        <option value=''>Select Location</option>
                        {siteId > 0 &&
                          locationData?.locations?.map((item: any, idx: number) => {
                            return (
                              <option key={idx} value={item.id}>
                                {item.location}
                              </option>
                            )
                          })}
                      </select>
                    </Col>
                  </Row>
                </div>
              )}
              {reserveFor === 3 && (
                <>
                  <Row className='mb-6'>
                    <Col lg='3'>
                      <label className='col-form-label fw-bold fs-6 required'>
                        <span>Customer</span>
                      </label>
                    </Col>
                    <Col lg='9' className='fv-row'>
                      <div className='d-flex'>
                        <Select
                          onChange={handleSelectChangeCustomer}
                          options={customerOptions}
                          value={initialCustomer}
                          isClearable={true}
                          isSearchable={true}
                          placeholder='Select a Customer'
                          classNamePrefix='custom-select'
                          className='custom-react-select w-100'
                        />
                        <button
                          type='button'
                          className='btn btn-light-primary d-flex align-items-center ms-3'
                          onClick={() => handleShowCustomerModal()}
                        >
                          <i className='la la-plus fs-3' />
                          New
                        </button>
                        <CustomersModal
                          show={showCustomerModal}
                          handleClose={handleCloseCustomerModal}
                          CustomerData={[]}
                        />
                      </div>
                      {customErrors && <span style={{ color: 'red' }}>customer is required</span>}
                      {formik.touched.customerId && formik.errors.customerId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{String(formik.errors.customerId)}</div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className='mb-6'>
                    <Col lg='3' className='col-form-label fw-bold fs-6'>
                      <div className='form-check form-check-custom  form-check-sm d-flex align-center me-5 mb-4'>
                        <input
                          className='form-check-input me-2'
                          type='checkbox'
                          id='flexRadioLg'
                          checked={emailEnabled}
                          onChange={() => setEmailEnabled(!emailEnabled)}
                        />
                        <label className='form-label mb-0 fw-bold fs-6' htmlFor='flexRadioLg'>
                          Send Email
                        </label>
                      </div>
                    </Col>
                    <Col lg='9' className='fv-row'>
                      <div>
                        <input
                          type='email'
                          disabled={emailEnabled == true ? false : true}
                          className='form-control form-control-solid'
                          value={selectedPersonValue ? selectedPersonValue : selectedCustomerValue}
                          placeholder='Email'
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className='mb-6'>
                    <div className={emailEnabled ? 'mb-10' : 'd-none'}>
                      <div className='d-flex align-items-center custom-emails'>
                        <div className='form-check form-check-custom  form-check-sm d-flex align-center'>
                          <input
                            className='form-check-input me-2'
                            type='checkbox'
                            id='flexRadioLg_1'
                            checked={firstEmail}
                            onChange={() => {
                              setFirstEmail(!firstEmail)
                              if (!firstEmail) {
                                formik.setFieldValue('reminder1', undefined)
                              }
                            }}
                          />
                          <label className='form-label mb-0 fw-bold fs-6' htmlFor='flexRadioLg_1'>
                            Reminder Email #1
                          </label>
                        </div>
                        <input
                          type='text'
                          disabled={firstEmail == true ? false : true}
                          {...formik.getFieldProps('reminder1')}
                          className='form-control form-control-solid mx-3'
                          placeholder='1'
                          onChange={(e) => {
                            const value = e.target.value
                            if (decimalPlacesRegex.test(value)) {
                              formik.setFieldValue('reminder1', value)
                            }
                          }}
                          onKeyDown={(e) => {
                            nonNumericKeys(e)
                          }}
                        />
                        <label className='form-label mb-0 fw-bold fs-6'>Days before Event</label>
                      </div>
                    </div>
                    <div className={emailEnabled ? 'mb-10' : 'd-none'}>
                      <div className='d-flex align-items-center custom-emails'>
                        <div className='form-check form-check-custom  form-check-sm d-flex align-center'>
                          <input
                            className='form-check-input me-2'
                            type='checkbox'
                            id='flexRadioLg_2'
                            checked={secondEmail}
                            onChange={() => {
                              setSecondEmail(!secondEmail)
                              if (!secondEmail) {
                                formik.setFieldValue('reminder2', undefined)
                              }
                            }}
                          />
                          <label className='form-label mb-0 fw-bold fs-6' htmlFor='flexRadioLg_2'>
                            Reminder Email #2
                          </label>
                        </div>
                        <input
                          type='text'
                          disabled={secondEmail == true ? false : true}
                          {...formik.getFieldProps('reminder2')}
                          className='form-control form-control-solid mx-3'
                          placeholder='2'
                          onChange={(e) => {
                            const value = e.target.value
                            if (decimalPlacesRegex.test(value)) {
                              formik.setFieldValue('reminder2', value)
                            }
                          }}
                          onKeyDown={(e) => {
                            nonNumericKeys(e)
                          }}
                        />
                        <label className='form-label mb-0 fw-bold fs-6'>Days before Event</label>
                      </div>
                    </div>
                  </Row>
                </>
              )}
              {reserveFor == 1 && (
                <>
                  <Row className='mb-6'>
                    <Col lg='3'>
                      <label className='col-form-label fw-bold fs-6 required'>
                        <span>Person</span>
                      </label>
                    </Col>
                    <Col lg='9' className='fv-row'>
                      <div className='d-flex'>
                        <Select
                          onChange={handleSelectChange}
                          options={personOptions}
                          value={initialPerson}
                          isClearable={true}
                          isSearchable={true}
                          placeholder='Select a Person'
                          classNamePrefix='custom-select'
                          className='custom-react-select w-100'
                        />
                        <button
                          type='button'
                          className='btn btn-light-primary d-flex align-items-center ms-3'
                          onClick={() => handleShowPersonModal()}
                        >
                          <i className='la la-plus fs-3' />
                          New
                        </button>
                        <PersonsModal
                          show={showPersonModal}
                          handleClose={handleClosePersonModal}
                          personData={[]}
                        />
                      </div>
                      {personFieldError && <span style={{ color: 'red' }}>person is required</span>}
                      {formik.touched.personId && formik.errors.personId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{String(formik.errors.personId)}</div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className='mb-6'>
                    <Col lg='3' className='col-form-label fw-bold fs-6'>
                      <div className='form-check form-check-custom  form-check-sm d-flex align-center me-5 mb-4'>
                        <input
                          className='form-check-input me-2'
                          type='checkbox'
                          id='flexRadioLg_3'
                          checked={emailEnabled}
                          onChange={() => setEmailEnabled(!emailEnabled)}
                        />
                        <label className='form-label mb-0 fw-bold fs-6' htmlFor='flexRadioLg_3'>
                          Send Email
                        </label>
                      </div>
                    </Col>
                    <Col lg='9' className='fv-row'>
                      <div>
                        <input
                          type='email'
                          disabled={emailEnabled == true ? false : true}
                          className='form-control form-control-solid'
                          value={selectedPersonValue ? selectedPersonValue : selectedCustomerValue}
                          placeholder='Email'
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className='mb-6'>
                    <div className={emailEnabled ? 'mb-10' : 'd-none'}>
                      <div className='d-flex align-items-center custom-emails'>
                        <div className='form-check form-check-custom  form-check-sm d-flex align-center'>
                          <input
                            className='form-check-input me-2'
                            type='checkbox'
                            id='flexRadioLg_4'
                            checked={firstEmail}
                            onChange={() => {
                              setFirstEmail(!firstEmail)
                              if (!firstEmail) {
                                formik.setFieldValue('reminder1', undefined)
                              }
                            }}
                          />
                          <label className='form-label mb-0 fw-bold fs-6' htmlFor='flexRadioLg_4'>
                            Reminder Email #1
                          </label>
                        </div>
                        <input
                          type='text'
                          disabled={firstEmail == true ? false : true}
                          {...formik.getFieldProps('reminder1')}
                          className='form-control form-control-solid mx-3'
                          placeholder='1'
                          onChange={(e) => {
                            const value = e.target.value
                            if (decimalPlacesRegex.test(value)) {
                              formik.setFieldValue('reminder1', value)
                            }
                          }}
                          onKeyDown={(e) => {
                            nonNumericKeys(e)
                          }}
                        />
                        <label className='form-label mb-0 fw-bold fs-6'>Days before Event</label>
                      </div>
                    </div>
                    <div className={emailEnabled ? 'mb-10' : 'd-none'}>
                      <div className='d-flex align-items-center custom-emails'>
                        <div className='form-check form-check-custom  form-check-sm d-flex align-center'>
                          <input
                            className='form-check-input me-2'
                            type='checkbox'
                            id='flexRadioLg_5'
                            checked={secondEmail}
                            onChange={() => {
                              setSecondEmail(!secondEmail)
                              if (!secondEmail) {
                                formik.setFieldValue('reminder2', undefined)
                              }
                            }}
                          />
                          <label className='form-label mb-0 fw-bold fs-6' htmlFor='flexRadioLg_5'>
                            Reminder Email #2
                          </label>
                        </div>
                        <input
                          type='text'
                          disabled={secondEmail == true ? false : true}
                          {...formik.getFieldProps('reminder2')}
                          className='form-control form-control-solid mx-3'
                          placeholder='2'
                          onChange={(e) => {
                            const value = e.target.value
                            if (decimalPlacesRegex.test(value)) {
                              formik.setFieldValue('reminder2', value)
                            }
                          }}
                          onKeyDown={(e) => {
                            nonNumericKeys(e)
                          }}
                        />
                        <label className='form-label mb-0 fw-bold fs-6'>Days before Event</label>
                      </div>
                    </div>
                  </Row>
                </>
              )}
            </Col>
            <Col xl='12'>
              <Row className='mb-10'>
                <Col lg='3'>
                  <label className='col-form-label fw-bold fs-6'>Notes</label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <textarea
                      className='form-control'
                      {...formik.getFieldProps('notes')}
                      cols={20}
                      rows={4}
                    ></textarea>
                    {formik.touched.notes && formik.errors.notes && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{String(formik.errors.notes)}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className=' d-flex align-items-center justify-content-end'>
            <button onClick={handleClose} className='btn btn-light-primary   main-btn-style me-2'>
              Cancel
            </button>
            {reserveFor === 1 && (
              <button
                type='submit'
                className='btn btn-primary'
                disabled={
                  selectedPersonValue === '' ||
                  selectedPersonValue === null ||
                  personFieldError ||
                  !formik.isValid
                }
              >
                {!isAdding && 'Reserve'}
                {isAdding && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )}
            {reserveFor === 2 && (
              <button
                type='submit'
                className='btn btn-primary'
                disabled={siteId === undefined || siteId === 0 || siteFieldError || !formik.isValid}
              >
                {!isAdding && 'Reserve'}
                {isAdding && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )}{' '}
            {reserveFor === 3 && (
              <button
                type='submit'
                className='btn btn-primary'
                disabled={
                  selectedCustomerValue === '' ||
                  selectedCustomerValue === null ||
                  customErrors ||
                  !formik.isValid
                }
              >
                {!isAdding && 'Reserve'}
                {isAdding && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )}
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AddReservationModal
