import { useGetSubscriptionQuery } from 'App/Modules/Services/Payments'
import { decrypt } from 'config'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
interface HeaderListingProps {
  assetsData: any
  fileName: any
  userAssets: any
  deleteDataArr: number[]
  permission: any
  staffUser: boolean
  setDeleteModal: (value: boolean) => void
  setCheck: (ids: number[]) => void
  setShowSearch: (value: boolean) => void
  setShowSetupColumns: (value: boolean) => void
  handleExport: () => void
  setRef: any
  handleMaintenanceFilter: any
  selectFilterRef: React.RefObject<HTMLSelectElement> // Add selectFilterRef prop
}
const MaintenancesHeader = (props: HeaderListingProps) => {
  const navigate = useNavigate()
  const { data: activePlanDetails, isLoading: activePlanDetailsLoading } = useGetSubscriptionQuery(
    {}
  )
  const activePlanImportExport =
    decrypt(activePlanDetails?.activeSubscription?.plan?.isImportExport) ||
    activePlanDetails?.activeSubscription?.isImportExport

  useEffect(() => {
    if (props.selectFilterRef.current) {
      props.setRef(true)
    }
  }, [props.selectFilterRef.current])

  return (
    <>
      <div
        className='d-flex justify-content-between mb-3 flex-wrap'
        data-kt-user-table-toolbar='base'
      >
        <div className='d-flex align-items-center mb-xl-0 mb-4'>
          <h3 className='card-title me-4 mb-0'>
            <span className='me-2 align-middle'>
              <i className='la la-list fs-1' aria-hidden='true'></i>
            </span>
            <span className='card-label fw-bold fs-3 mb-1'>List of Maintenances</span>
          </h3>
        </div>
        <div className='d-flex flex-wrap'>
          {!props.permission?.viewAsset && props.staffUser ? (
            ''
          ) : (
            <button
              type='button'
              className='btn btn-light-primary main-btn-style m-1 d-none'
              onClick={() => props.setShowSearch(true)}
            >
              <i className='fa fa-search fs-xl-5 fs-6' /> Search
              <span className='d-xl-inline d-none'> Criteria</span>
            </button>
          )}
          {!props.permission?.exportMaintenance && props.staffUser
            ? ''
            : activePlanImportExport && (
                <button
                  className='btn btn-light-primary main-btn-style m-1'
                  onClick={() => {
                    props.handleExport()
                  }}
                >
                  <i className='far fa-file-excel fs-5'></i> Export
                  <span className='d-xl-inline d-none'> to Excel</span>
                </button>
              )}
          {!props.permission?.importMaintenance && props.staffUser
            ? ''
            : activePlanImportExport && (
                <Link
                  className='btn btn-light-primary main-btn-style d-flex align-items-center m-1'
                  to='/import'
                  state={{ importType: 'maintenances' }}
                >
                  <i className='la la-cloud-upload fs-2 me-1'></i>
                  <span>Import</span>
                  <span className='d-xl-inline d-none ps-1'>Maintenances</span>
                </Link>
              )}
          {props.deleteDataArr.length > 0 && (
            <button
              type='button'
              className='btn btn-danger main-btn-style m-1'
              onClick={() => {
                props.setDeleteModal(true)
                props.setCheck(props.deleteDataArr)
              }}
            >
              Delete Selected ({props.deleteDataArr.length})
            </button>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <div className='form-group mb-3'>
            <select
              ref={props.selectFilterRef}
              className='form-select'
              onChange={props.handleMaintenanceFilter}
            >
              <option value='All'>All Maintenances (except cancelled and completed)</option>
              <option value='Due'>Maintenance Due</option>
              <option value='OverDue'>Maintenance Overdue</option>
              <option value='DueInFuture'>Maintenance Due in future</option>
              <option value='Completed'>Maintenance Completed</option>
              <option value='Cancelled'>Maintenance Cancelled</option>
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

export { MaintenancesHeader }
