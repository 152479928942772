import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import { Link, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import {
  useBankDetailsMutation,
  useGetBankDetailsQuery,
} from 'App/Modules/Services/AccountsDetails'
import { toast } from 'react-toastify'
import { decrypt, encrypt, nameRegex } from 'config'

interface CardDetailsProps {
  onCardChange: (data: any) => void
  setIsFormValid: any
}
interface Account {
  cardHolderName?: string
  cardType?: string
  cardNumber?: string
  expiryMonth?: string
  expiryYear?: string
  cvc?: string
}
interface BankDetails {
  account?: Account
}

const CardDetails: React.FC<CardDetailsProps> = ({ onCardChange, setIsFormValid }) => {
  const location = useLocation()
  const { data: bankDetails } = useGetBankDetailsQuery() as { data?: BankDetails }
  const [card, { isLoading }] = useBankDetailsMutation()
  const validationSchema = Yup.object().shape({
    cardHolderName: Yup.string()
      .trim()
      .required('Card Holder Name is required')
      .max(20, 'Card Holder Name has no more than 20 characters.')
      .matches(nameRegex, 'Card Holder Name should only contain letters'),
    cardType: Yup.string().required('Card Type is required'),
    cardNumber: Yup.string()
      .matches(/^[0-9]{16}$/, 'Card Number must be exactly 16 digits')
      .required('Card Number is required'),
    expiryMonth: Yup.string()
      .matches(/^([1-9]|1[0-2])$/, 'Enter a valid month between 1 and 12')
      .required('Expiry Month is required'),
    expiryYear: Yup.string()
      .matches(/^[0-9]{2}$/, 'Card Expiry Year must be exactly 2 digits')
      .required('Expiry Year is required')
      .test('is-not-past', 'Expiry date cannot be in the past', function (expiryYear) {
        const { expiryMonth } = this.parent
        if (!expiryMonth || !expiryYear) return true
        const now = new Date()
        const currentYear = now.getFullYear() % 100
        const currentMonth = now.getMonth() + 1

        const cardYear = parseInt(expiryYear, 10)
        const cardMonth = parseInt(expiryMonth, 10)

        return cardYear > currentYear || (cardYear === currentYear && cardMonth >= currentMonth)
      }),
    cvc: Yup.string()
      .matches(/^[0-9]{3,4}$/, 'CVC must be 3 or 4 digits')
      .required('CVC is required'),
  })

  const formik = useFormik({
    initialValues: {
      cardHolderName: '',
      cardType: '',
      cardNumber: '',
      expiryMonth: '',
      expiryYear: '',
      cvc: '',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      try {
        const encryptedData = {
          cardHolderName: encrypt(values.cardHolderName),
          cardType: encrypt(values.cardType),
          cardNumber: encrypt(values.cardNumber),
          expiryMonth: encrypt(values.expiryMonth),
          expiryYear: encrypt(values.expiryYear),
          cvc: encrypt(values.cvc),
        }
        const response = await card(encryptedData).unwrap()
        if (response.success) {
          toast.success(response?.message)
        }
      } catch (error) {
        console.error('Error submitting billing details:', error)
      }
    },
  })

  const handleButtonClick = () => {
    formik.handleSubmit()
  }

  useEffect(() => {
    if (bankDetails) {
      const bank = bankDetails.account
      formik.setValues({
        cardHolderName: bank?.cardHolderName ? decrypt(bank?.cardHolderName) : '',
        cardType: bank?.cardType ? decrypt(bank?.cardType) : '',
        cardNumber: bank?.cardNumber ? decrypt(bank?.cardNumber) : '',
        expiryMonth: bank?.expiryMonth ? decrypt(bank?.expiryMonth) : '',
        expiryYear: bank?.expiryYear ? decrypt(bank?.expiryYear) : '',
        cvc: bank?.cvc ? decrypt(bank?.cvc) : '',
      })
    }
  }, [bankDetails])
  useEffect(() => {
    setIsFormValid(Object.keys(formik.errors).length === 0 && formik.touched)
    onCardChange(formik.values)
  }, [formik.values, formik.errors, formik.touched, onCardChange])

  return (
    <form className='payment-mathed'>
      <Row className='mb-6'>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='cardHolderName'>
                Card Holder Name
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='cardHolderName'
                className={`form-control form-control-solid ${formik.touched.cardHolderName && formik.errors.cardHolderName ? 'is-invalid' : ''}`}
                placeholder='Card Holder Name'
                {...formik.getFieldProps('cardHolderName')}
              />
              {formik.touched.cardHolderName && formik.errors.cardHolderName ? (
                <div className='invalid-feedback'>{formik.errors.cardHolderName}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='cardNumber'>
                Card Number
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='cardNumber'
                className={`form-control form-control-solid ${formik.touched.cardNumber && formik.errors.cardNumber ? 'is-invalid' : ''}`}
                placeholder='Card Number'
                {...formik.getFieldProps('cardNumber')}
              />
              {formik.touched.cardNumber && formik.errors.cardNumber ? (
                <div className='invalid-feedback'>{formik.errors.cardNumber}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='expiryMonth'>
                Expiry Month
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='expiryMonth'
                className={`form-control form-control-solid ${formik.touched.expiryMonth && formik.errors.expiryMonth ? 'is-invalid' : ''}`}
                placeholder='Card Expiry Month'
                {...formik.getFieldProps('expiryMonth')}
              />
              {formik.touched.expiryMonth && formik.errors.expiryMonth ? (
                <div className='invalid-feedback'>{formik.errors.expiryMonth}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='expiryYear'>
                Expiry Year
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='expiryYear'
                className={`form-control form-control-solid ${formik.touched.expiryYear && formik.errors.expiryYear ? 'is-invalid' : ''}`}
                placeholder='Card Expiry Year 25'
                {...formik.getFieldProps('expiryYear')}
              />
              {formik.touched.expiryYear && formik.errors.expiryYear ? (
                <div className='invalid-feedback'>{formik.errors.expiryYear}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='cardType'>
                Card Type
              </label>
            </Col>
            <Col lg='8'>
              <select
                id='cardType'
                className={`form-control form-control-solid ${formik.touched.cardType && formik.errors.cardType ? 'is-invalid' : ''}`}
                {...formik.getFieldProps('cardType')}
              >
                <option value=''>Select Card Type</option>
                <option value='visa'>Visa</option>
              </select>
              {formik.touched.cardType && formik.errors.cardType ? (
                <div className='invalid-feedback'>{formik.errors.cardType}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='cvc'>
                CVC
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='cvc'
                className={`form-control form-control-solid ${formik.touched.cvc && formik.errors.cvc ? 'is-invalid' : ''}`}
                placeholder='CVC'
                {...formik.getFieldProps('cvc')}
              />
              {formik.touched.cvc && formik.errors.cvc ? (
                <div className='invalid-feedback'>{formik.errors.cvc}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
      {location.pathname === '/account-details/overview' && (
        <Row>
          <Col>
            <div className='d-flex justify-content-end align-items-center'>
              <button
                type='button'
                className='btn btn-primary main-btn-style'
                onClick={handleButtonClick}
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isLoading && 'Save'}
                {isLoading && (
                  <span className='indicator-progress d-block'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Col>
        </Row>
      )}
    </form>
  )
}

export default CardDetails
