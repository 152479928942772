import { useSendEmailSubUserMutation } from 'App/Modules/Services/SubUser'
import { toast } from 'react-toastify'
interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
  setSendEmail?: any
}

export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData, setSendEmail }) => {
  const [sendEmailMutation] = useSendEmailSubUserMutation()
  const capitalizeFirstLetter = (string) => {
    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  const sendEmail = async (id: number) => {
    const userType = 3
    try {
      const data = await sendEmailMutation({ id, userType }).unwrap()
      const { success, message } = data
      if (!success) return toast.error(message)
      toast.success(message)
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
      }
    }
  }

  const renderData = () => {
    switch (column) {
      case 'First Name':
        return assetsData.allAssets.map((person: any, index: number) => (
          <div>
            <div className='text-truncate me-2'>
              {`${capitalizeFirstLetter(person.firstName)}`}
            </div>
          </div>
        ))
      case 'Last Name':
        return assetsData.allAssets.map((person: any, index: number) => (
          <div>
            <div className='text-truncate me-2'>
              {`${capitalizeFirstLetter(person.lastName)}`}
            </div>
          </div>
        ))
      case 'Created By':
        return assetsData.allAssets.map((person: any, index: number) => (
          <span key={index}>
            {person?.user ? `${person?.user?.firstName} ${person?.user?.lastName}` : ''}
          </span>
        ))
      case 'Group':
        return assetsData.allAssets.map((person: any, index: number) => (
          <span key={index}>{person?.security_group?.name || ''}</span>
        ))
      case 'Department':
        return assetsData.allAssets.map((person: any, index: number) => (
          <span key={index}>{person?.Department?.department}</span>
        ))
      case 'Email':
        return assetsData.allAssets.map((person: any, index: number) => (
          <div>
            <span key={index}>{person?.email}</span>
            <div>
              {!person?.isEmailVerified ? (
                <button
                  className='btn bg-success fw-bold text-white px-3 py-2 fs-8'
                  onClick={() => {
                    sendEmail(person?.id)
                    setSendEmail(true)
                  }}
                >
                  {'Send Set Password Email'}
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        ))
      case 'Employee Id':
        return assetsData.allAssets.map((person: any, index: number) => (
          <span key={index}>{person?.employeeId}</span>
        ))
      case 'Location':
        return assetsData.allAssets.map((person: any, index: number) => (
          <span key={index}>{person?.Location?.location}</span>
        ))
      case 'Notes':
        return assetsData.allAssets.map((person: any, index: number) => (
          <span key={index}>{person?.notes}</span>
        ))
      case 'Phone':
        return assetsData.allAssets.map((person: any, index: number) => (
          <span key={index}>{person?.phone}</span>
        ))
      case 'Site':
        return assetsData.allAssets.map((person: any, index: number) => (
          <span key={index}>{person?.Site?.name}</span>
        ))
      case 'Title':
        return assetsData.allAssets.map((person: any, index: number) => (
          <span key={index}>{person?.title}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const renderData = () => {
    switch (column) {
      case 'Created By':
        return 'Sample Created By'
      case 'First Name':
        return 'Sample First Name'
      case 'Last Name':
        return 'Sample Last Name'
      case 'Group':
        return 'Admin Group'
      case 'Department':
        return 'Sample Department'
      case 'Email':
        return 'Sample Email'
      case 'Employee Id':
        return 'Sample Employee Id'
      case 'Location':
        return 'Sample Location'
      case 'Notes':
        return 'Sample Notes'
      case 'Phone':
        return 'Sample Phone'
      case 'Site':
        return 'Sample Site'
      case 'Title':
        return 'Sample Title'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
