import React from 'react'
import './FailurePayment.css'

const FailurePayment: React.FC = () => {
   const queryParams = new URLSearchParams(window.location.search)
  const message = queryParams.get('message') || 'Failure'
  return (
    <div className='main-success'>
      <div className='failure-container'>
        <div className='failure-icon d-flex justify-content-center align-items-center'>
          <div className='success-bg failure-bg  d-flex justify-content-center align-items-center'>
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
        <h2 className='failure-title'>{message}</h2>
        <p className='failure-description'>
          Your payment could not be processed. It looks like there was an issue with your payment.
          Please try again.
        </p>
        <a href='/dashboard' className='success-button'>
          Go to Dashboard
        </a>
      </div>
    </div>
  )
}

export default FailurePayment
