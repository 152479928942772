import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useFormik } from 'formik'
import { useLocation } from 'react-router'
import {
  useBillingDetailsMutation,
  useGetBillingDetailsQuery,
} from 'App/Modules/Services/AccountsDetails'
import { toast } from 'react-toastify'
import { billingValidationSchema, initialBillingDetails } from './Core/BillingModel'
import { decrypt, encrypt } from 'config'

interface BillingDetailsProps {
  onBillingChange: (data: any) => void
  setIsBillingValid: any
}

interface Biling {
  firstName?: string
  lastName?: string
  email?: string
  street?: string
  floor?: string
  phoneNumber?: string
  country?: string
  state?: string
  apartment?: string
}

interface BillingDetails {
  billing?: Biling
}

const BillingDetails: React.FC<BillingDetailsProps> = ({ onBillingChange, setIsBillingValid }) => {
  const location = useLocation()
  const [billing, { isLoading }] = useBillingDetailsMutation()
  const { data: billingDetails } = useGetBillingDetailsQuery() as { data?: BillingDetails }

  useEffect(() => {
    if (billingDetails) {
      const decryptedBilling = {
        firstName: billingDetails?.billing?.firstName
          ? decrypt(billingDetails.billing.firstName)
          : '',
        lastName: billingDetails?.billing?.lastName ? decrypt(billingDetails.billing.lastName) : '',
        email: billingDetails?.billing?.email ? decrypt(billingDetails.billing.email) : '',
        street: billingDetails?.billing?.street ? decrypt(billingDetails.billing.street) : '',
        floor: billingDetails?.billing?.floor ? decrypt(billingDetails.billing.floor) : '',
        phoneNumber: billingDetails?.billing?.phoneNumber
          ? decrypt(billingDetails.billing.phoneNumber)
          : '',
        country: billingDetails?.billing?.country ? decrypt(billingDetails.billing.country) : '',
        state: billingDetails?.billing?.state ? decrypt(billingDetails.billing.state) : '',
        apartment: billingDetails?.billing?.apartment
          ? decrypt(billingDetails.billing.apartment)
          : '',
      }

      formik.setValues(decryptedBilling)
    }
  }, [billingDetails])
  const formik = useFormik({
    initialValues: initialBillingDetails,
    enableReinitialize: true,
    validationSchema: billingValidationSchema,
    onSubmit: async (values) => {
      try {
        const encryptedData = {
          firstName: encrypt(values.firstName),
          lastName: encrypt(values.lastName),
          email: encrypt(values.email),
          street: encrypt(values.street),
          floor: encrypt(values.floor),
          phoneNumber: encrypt(values.phoneNumber),
          country: encrypt(values.country),
          state: encrypt(values.state),
          apartment: encrypt(values.apartment),
        }
        const response = await billing(encryptedData).unwrap()
        if (response.success) {
          toast.success(response?.message)
        }
      } catch (error) {
        console.error('Error submitting billing details:', error)
      }
    },
  })

  const handleButtonClick = () => {
    formik.handleSubmit()
  }
  useEffect(() => {
    setIsBillingValid(Object.keys(formik.errors).length === 0 && formik.touched)
    onBillingChange(formik.values)
  }, [formik.values, formik.errors, formik.touched, onBillingChange])

  return (
    <form>
      <Row className='mb-6'>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='firstName'>
                First Name
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='firstName'
                className={`form-control form-control-solid ${formik.touched.firstName && formik.errors.firstName ? 'is-invalid' : ''}`}
                placeholder='First Name'
                {...formik.getFieldProps('firstName')}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className='invalid-feedback'>{formik.errors.firstName}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='lastName'>
                Last Name
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='lastName'
                className={`form-control form-control-solid ${formik.touched.lastName && formik.errors.lastName ? 'is-invalid' : ''}`}
                placeholder='Last Name'
                {...formik.getFieldProps('lastName')}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className='invalid-feedback'>{formik.errors.lastName}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='email'>
                Email
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='email'
                id='email'
                className={`form-control form-control-solid ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                placeholder='Email'
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='invalid-feedback'>{formik.errors.email}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='phoneNumber'>
                Phone Number
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='phoneNumber'
                className={`form-control form-control-solid ${formik.touched.phoneNumber && formik.errors.phoneNumber ? 'is-invalid' : ''}`}
                placeholder='Phone Number'
                {...formik.getFieldProps('phoneNumber')}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className='invalid-feedback'>{formik.errors.phoneNumber}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='country'>
                Country
              </label>
            </Col>
            <Col lg='8'>
              <input
                id='country'
                className={`form-control form-control-solid ${formik.touched.country && formik.errors.country ? 'is-invalid' : ''}`}
                placeholder='Country'
                {...formik.getFieldProps('country')}
              />
              {formik.touched.country && formik.errors.country ? (
                <div className='invalid-feedback'>{formik.errors.country}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='street'>
                Street
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='street'
                className={`form-control form-control-solid ${formik.touched.street && formik.errors.street ? 'is-invalid' : ''}`}
                placeholder='Street'
                {...formik.getFieldProps('street')}
              />
              {formik.touched.street && formik.errors.street ? (
                <div className='invalid-feedback'>{formik.errors.street}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label required fw-bold fs-6' htmlFor='state'>
                State
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='state'
                className={`form-control form-control-solid ${formik.touched.state && formik.errors.state ? 'is-invalid' : ''}`}
                placeholder='State'
                {...formik.getFieldProps('state')}
              />
              {formik.touched.state && formik.errors.state ? (
                <div className='invalid-feedback'>{formik.errors.state}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label fw-bold fs-6' htmlFor='floor'>
                Floor
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='floor'
                className='form-control form-control-solid'
                placeholder='Floor'
                {...formik.getFieldProps('floor')}
              />
              {formik.touched.floor && formik.errors.floor ? (
                <div className='invalid-feedback'>{formik.errors.floor}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col xl='6'>
          <Row className='mb-3'>
            <Col lg='4'>
              <label className='col-form-label fw-bold fs-6' htmlFor='apartment'>
                Apartment
              </label>
            </Col>
            <Col lg='8'>
              <input
                type='text'
                id='apartment'
                className='form-control form-control-solid'
                placeholder='Apartment'
                {...formik.getFieldProps('apartment')}
              />
              {formik.touched.apartment && formik.errors.apartment ? (
                <div className='invalid-feedback'>{formik.errors.apartment}</div>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
      {location.pathname === '/account-details/overview' && (
        <Row>
          <Col>
            <div className='d-flex justify-content-end align-items-center'>
              <button
                type='button'
                className='btn btn-primary main-btn-style'
                onClick={handleButtonClick}
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isLoading && 'Save'}
                {isLoading && (
                  <span className='indicator-progress d-block'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </Col>
        </Row>
      )}
    </form>
  )
}

export default BillingDetails
