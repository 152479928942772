import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const planApi = createApi({
  reducerPath: 'planApi',
  baseQuery: customFetchBaseQuery({ path: 'plan' }),
  tagTypes: ['Plans'],
  endpoints: (build) => ({
    getAllPlans: build.query<any, any>({
      query: () => ({
        url: 'get-all',
        method: 'GET',
      }),
      providesTags: ['Plans'],
    }),
    addPlan: build.mutation<any, any>({
      query: (body) => ({
        url: `add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Plans' }],
    }),
  }),
})

export const { useGetAllPlansQuery, useAddPlanMutation } = planApi
