import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import CompanyInfo from './CompnayInfo/CompanyInfo'
import Sites from './Sites/Sites'
import Location from './Locations/Location'
import Categories from './Categories/Categories'
import Assets from './Asset/Assets'
import { TableOption } from './TableOptions/TableOption'
import Events from './Event/Components/Events'
import { wizardArray } from 'config'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import { useAuth } from 'App/Modules/Auth'
import { toast } from 'react-toastify'
import { useUpdateSetupMutation } from 'App/Modules/Services/Profile'
import { useDispatch } from 'react-redux'
import { refresh } from 'App/Store'

function SetupWizard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const { data: companyData } = useGetCompanyQuery({})
  const [updateSetup] = useUpdateSetupMutation()
  const [setupPage, setSetupPage] = useState<string>('company')
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [showContinue, setShowContinue] = useState(false)
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4

  useEffect(() => {
    if (companyData) {
      setShowContinue(true)
    }
  }, [companyData])

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (currentUser?.onStep) {
      const step = currentUser.onStep - 1
      setSetupPage(wizardArray[step]?.path)
      setSelectedIndex(step)
    }
  }, [currentUser])

  const handleContinue = async () => {
    if (selectedIndex < wizardArray.length - 1) {
      setSelectedIndex(selectedIndex + 1)
      setSetupPage(wizardArray[selectedIndex + 1].path)
      await updateSetup({ isCompleted: false, onStep: selectedIndex + 1 + 1 })
      dispatch(refresh(true))
      goToTop()
    }
  }

  return (
    <div className='wizard-wrap'>
      <div id='kt_content_container' className='container'>
        <div className='card rounded-bottom-0'>
          <div className='border-bottom'>
            <div className='wizard-steps'>
              {wizardArray.map((item, index) => {
                return (
                  <span className='wizard-step' key={index}>
                    <div className='wizard-label'>
                      <span className='wizard-icon'>
                        <svg fill={`${index <= selectedIndex ? '#001a42' : '#838383'}`}>
                          {item.image}
                        </svg>
                      </span>
                      <h2 className={`wizard-title ${index <= selectedIndex ? 'active' : ''}`}>
                        {item.title}
                      </h2>
                    </div>
                    {index === wizardArray.length - 1 ? null : (
                      <span className={`wizard-arrow ${index <= selectedIndex ? 'active' : ''}`}>
                        <i className='fas fa-chevron-right' aria-hidden='true'></i>
                      </span>
                    )}
                  </span>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='wizard-content'>
        {setupPage === 'company' ? (
          <CompanyInfo showContinue={showContinue} handleContinue={handleContinue} />
        ) : setupPage === 'sites' ? (
          <Sites />
        ) : setupPage === 'location' ? (
          <Location />
        ) : setupPage === 'categories' ? (
          <Categories />
        ) : setupPage === 'database' ? (
          <Assets />
        ) : setupPage === 'table' ? (
          <TableOption />
        ) : setupPage === 'event' ? (
          <Events />
        ) : null}
      </div>
      {setupPage !== 'company' ? (
        <div id='kt_content_container' className='container'>
          <div className='card rounded-top-0'>
            <div className='card-footer'>
              <div className='d-flex justify-content-end align-items-center flex-wrap'>
                <button
                  className='btn btn-light-primary   main-btn-style me-2'
                  onClick={() => {
                    if (selectedIndex !== 0) {
                      setSelectedIndex(selectedIndex - 1)
                      setSetupPage(wizardArray[selectedIndex - 1].path)
                      updateSetup({ isCompleted: false, onStep: selectedIndex - 1 + 1 })
                      goToTop()
                    }
                  }}
                >
                  Back
                </button>
                {selectedIndex !== wizardArray.length - 1 ? (
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      handleContinue()
                    }}
                  >
                    Continue
                  </button>
                ) : (
                  <button
                    className='btn btn-primary'
                    onClick={async () => {
                      await updateSetup({ isCompleted: true, onStep: selectedIndex + 1 })
                      if (!(personUser || customerUser)) {
                        navigate('/dashboard')
                      } else {
                        navigate('/assets')
                      }
                      window.location.reload()
                    }}
                  >
                    Finish
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default SetupWizard
