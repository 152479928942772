import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes_'
import { ErrorsPage } from '../Modules/Errors/ErrorsPage'
import { AuthPage, useAuth } from '../Modules/Auth'
import { App } from '../App'
import { TermsService } from '../Modules/Auth/Components/TermsService'
import Home from 'App/Modules/Home/Home'
import 'react-datepicker/dist/react-datepicker.css'

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
            </>
          ) : (
            <>
              <Route path='/' element={<Home />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
              <Route path='terms-service' element={<TermsService />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
