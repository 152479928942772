import * as Yup from 'yup'
import { nameRegex, phoneRegex } from 'config'

export type Billing = {
  firstName: string
  lastName: string
  email: string
  street: string
  floor?: string
  phoneNumber: string
  country: string
  state: string
  apartment?: string
}

export const initialBillingDetails: Billing = {
  firstName: '',
  lastName: '',
  email: '',
  street: '',
  floor: '',
  phoneNumber: '',
  country: '',
  state: '',
  apartment: '',
}

export const billingValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First Name is required')
    .max(20, 'First Name has no more than 20 characters.')
    .matches(nameRegex, 'First Name should only contain letters'),
  lastName: Yup.string()
    .trim()
    .required('Last Name is required')
    .max(20, 'Last Name has no more than 20 characters.')
    .matches(nameRegex, 'Last Name should only contain letters'),
  email: Yup.string()
    .email('Invalid email address')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  street: Yup.string()
    .trim()
    .required('Street is required')
    .max(20, 'Street has no more than 20 characters.'),
  floor: Yup.string().trim().max(20, 'Floor has no more than 20 characters.'),
  phoneNumber: Yup.string()
    .min(10, 'Number should be 10 or more digits')
    .max(15, 'Number should be less then or equal to 15 digits')
    .matches(phoneRegex, 'Only digits and plus sign on start are allowed')
    .nullable(),
  country: Yup.string()
    .trim()
    .required('Country is required')
    .max(20, 'Country has no more than 20 characters.')
    .matches(nameRegex, 'Country should only contain letters'),
  state: Yup.string()
    .trim()
    .required('State is required')
    .max(20, 'State has no more than 20 characters.')
    .matches(nameRegex, 'State should only contain letters'),
  apartment: Yup.string().trim().max(20, 'Apartment has no more than 20 characters.'),
})
