import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { toAbsoluteUrl } from 'Template/helpers';
import { Images } from 'Template/assets/Assets';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'App/Modules/Auth';
import { Link as ScrollLink, scroller } from 'react-scroll';
import './Header.css';

function Header() {
  const [mobileMenu, setMobileMenu] = useState(window.innerWidth > 991);
  const { currentUser } = useAuth();
  const [activeLink, setActiveLink] = useState('home');
  const location = useLocation();

  const handleLinkToggle = (link) => {
    if (window.innerWidth <= 991) {
      setMobileMenu(!mobileMenu);
    }
    setActiveLink(link);
  };

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      scroller.scrollTo(hash, {
        smooth: true,
        duration: 500,
        offset: -70,
      });
    }
  }, [location.hash]);

  return (
    <header className="main-header">
      <Navbar expand="lg" className="py-4" expanded={mobileMenu}>
        <Container className="custom-container">
          <Link to="/" className="brand-logo navbar-brand">
            <div className="image">
              <img
                src={toAbsoluteUrl(Images.DefaultWhiteLogo)}
                alt="Logo"
                width="100%"
                height="100%"
              />
            </div>
          </Link>
          <div className="d-flex">
            <Navbar.Collapse id="basic-navbar-nav" className={mobileMenu ? 'show' : ''}>
              <Nav className="ms-auto align-items-lg-center">
                {['home', 'review', 'features', 'benefits', 'pricing', 'faqs', 'contact'].map(
                  (link) => (
                    <Nav.Item key={link}>
                      <Link
                        to={`/#${link}`}
                        onClick={() => handleLinkToggle(link)}
                        className={`nav-link ${activeLink === link ? 'active' : ''}`}
                      >
                        {link.charAt(0).toUpperCase() + link.slice(1)}
                      </Link>
                    </Nav.Item>
                  )
                )}
              </Nav>
            </Navbar.Collapse>
            <div className="header-btns d-flex me-lg-0 me-2">
              {!currentUser ? (
                <>
                  <Link to="/auth/registration" className="main-btn main-btn-white me-2">
                    <span className="d-lg-none d-block">
                      <i className="las la-user"></i>
                    </span>
                    <span className="d-lg-block d-none">Create an Account</span>
                  </Link>
                  <Link to="/auth" className="main-btn main-btn-yellow" onClick={handleLinkToggle}>
                    <span className="d-lg-none d-block">
                      <i className="las la-sign-in-alt"></i>
                    </span>
                    <span className="d-lg-block d-none">Sign In</span>
                  </Link>
                </>
              ) : (
                <Link
                  to="/dashboard"
                  className="main-btn main-btn-yellow"
                  onClick={handleLinkToggle}
                >
                  <span className="d-lg-none d-block">
                    <i className="las la-sign-in-alt"></i>
                  </span>
                  <span className="d-lg-block d-none">Dashboard</span>
                </Link>
              )}
            </div>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="main-btn"
              onClick={handleLinkToggle}
            />
          </div>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
