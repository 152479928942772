import { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useAuth } from '../../../Modules/Auth'
import { useGetAllAssetsQuery, useGetSingleAssetQuery } from 'App/Modules/Services/Assets'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import { Link } from 'react-router-dom'
import Loader from 'App/Loaders/BeatLoader'
import { toast } from 'react-toastify'
import AssetMinimalDetailModal from 'App/Pages/Checkout/Modal/AssetMinimalDetailModal'
import { currentUserAssets, getAssetStatusClass } from 'config'

const CheckinModal = (props: any) => {
  const { currentUser } = useAuth()
  const [modalShow, setModalShow] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [selected, setSelected] = useState<any>([])
  const [assets, setAssets] = useState<any>([])
  const [searchAssets, setSearchAssets] = useState<any>('')
  const [searchKeywords, setSearchKeywords] = useState<any>('')
  const [skip, setSkip] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [assetId, setAssetId] = useState<any>('')
  const { data: singleAssetData, isLoading: getAsset } = useGetSingleAssetQuery(
    { assetId: assetId },
    { skip: !assetId }
  )
  const { data: assetsData } = useGetAllAssetsQuery(
    {
      body: new URLSearchParams({
        keyword: searchKeywords,
        searchField: 'assetTagId,description',
        status: 'Checked out',
      }).toString(),
      page: props?.page,
      limit: props?.limit,
      keys: '',
    },
    { skip: !props?.page && !props?.limit }
  )
  const [pagination, setPagination] = useState<any>({})

  useEffect(() => {
    setSkip(false)
  }, [searchAssets])

  useEffect(() => {
    if (singleAssetData) {
      setLoading(false)
      setModalShow(true)
    }
  }, [singleAssetData])

  const clickDeatilAsset = (id) => {
    if (assetId === id) {
      return setModalShow(true)
    }
    setAssetId(id)
    setLoading(true)
  }

  const handleClose = () => {
    props.handleClose()
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      props.setPendingAssets(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      props.setPendingAssets([])
    }
  }

  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      if (!Array.isArray(prevSelected)) {
        prevSelected = []
      }
      const updatedSelected = prevSelected?.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
      const isAllSelected = updatedSelected.length === assets?.length
      setSelectAll(isAllSelected)
      return updatedSelected
    })
    props.setPendingAssets((prevSelected) => {
      if (!Array.isArray(prevSelected)) {
        prevSelected = []
      }
      if (prevSelected?.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  useEffect(() => {
    if (assetsData?.assets.length > 0) {
      setAssets(assetsData?.assets)
      setPagination(assetsData?.pagination)
      setSkip(true)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [assetsData])

  useEffect(() => {
    if (props?.selectedAssets?.length == 0) {
      setSelectAll(false)
      setSelected([])
      props.setPendingAssets([])
    } else {
      const selectedData = props?.selectedAssets?.map((item) => item?.id)
      setSelectAll(false)
      setSelected(selectedData)
      props.setPendingAssets(selectedData)
    }
  }, [props?.selectedAssets])

  const handlePageChange = (currentPage: any) => {
    setLoading(true)
    props.setPage(currentPage)
    setSkip(false)
  }

  const handleLimitChange = (newLimit: number) => {
    setLoading(true)
    props.setLimit(Number(newLimit))
    props.setPage(1)
    setSkip(false)
  }
  const currentPage = props?.page || 0
  const totalRecords = pagination?.total || 0
  const pageSize = props?.limit || 10
  const startRecord = (currentPage - 1) * pageSize + 1
  const endRecord = Math.min(currentPage * pageSize, totalRecords)

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleClose()
    }
  }
  const handleResetSearch = () => {
    setSearchKeywords('')
    setSearchAssets('')
  }
  const handleSearch = () => {
    if (!searchAssets) {
      return toast.error('please enter any value to search')
    }
    if (searchAssets === searchKeywords) {
      return
    }
    setLoading(true)
    setSearchKeywords(searchAssets)
  }

  return (
    <>
      <Modal
        className='custom-modal-lg px-2'
        show={props.show}
        onHide={props.handleCancel}
        backdrop='static'
        size='lg'
        onKeyDown={handleKeyDown}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Assets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='form-group mb-4'>
                <div className='search-input-wrapper position-relative'>
                  <input
                    className='form-control'
                    onChange={(e) => {
                      setSearchAssets(e.target.value)
                    }}
                    data-val='true'
                    data-val-length='Maximum length is 50.'
                    data-val-length-max='50'
                    id='Search'
                    placeholder='Search with Asset Tag Id or Description'
                    type='search'
                    value={searchAssets}
                  />
                  {searchAssets && (
                    <span className='reset-icon' onClick={handleResetSearch}>
                      <i className='fa fa-times fs-6 p-0'></i>
                    </span>
                  )}
                  <button className='btn btn-light-primary main-btn-style' onClick={handleSearch}>
                    <i className='fa fa-search fs-xxl-6 fs-7 p-0'></i>
                  </button>
                </div>
              </div>
            </div>
            <div className='d-flex limit-options align-items-center mb-4'>
              <span className='text-muted'>Showing</span>
              <select
                name='iTakeList'
                id='iTakeList'
                className='form-select form-select-sm fw-bold mx-3 border-1'
                value={props?.limit}
                onChange={(e) => handleLimitChange(Number(e.target.value))}
              >
                <option value='10'>10</option>
                <option value='15'>15</option>
                <option value='20'>20</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
              </select>
              <span className='text-muted'>entries</span>
            </div>
            <div className='card-title'>
              <div className='checkout-listing-table mb-5 table-responsive pb-4'>
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer modal-dataTable'
                  role='table'
                >
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      <th role='columnheader' className='w-10px pe-2'>
                        <div className='me-3'>
                          <input
                            type='checkbox'
                            className='form-check-input custom-form-check-input'
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </div>
                      </th>
                      <th role='columnheader'>Asset Tag ID</th>
                      <th role='columnheader'>Description</th>
                      <th role='columnheader'>Status</th>
                      <th role='columnheader'>Assigned to</th>
                      <th role='columnheader'>Site</th>
                      <th role='columnheader'>Location</th>
                      <th role='columnheader'>Lease to</th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                    {assetsData?.assets?.length ? (
                      assetsData?.assets?.map((item: any) => {
                        return (
                          <tr role='row'>
                            <td role='cell'>
                              <div>
                                <input
                                  type='checkbox'
                                  className='form-check-input custom-form-check-input'
                                  checked={selected?.includes(item.id)}
                                  onChange={() => handleCheckboxChange(item.id)}
                                />
                              </div>
                            </td>
                            <td role='cell'>
                              <div className=' overflow-hidden'>
                                <button
                                  onClick={() => clickDeatilAsset(item.id)}
                                  className='d-block table-elipses text-btn'
                                >
                                  {item?.assetTagId}
                                </button>
                              </div>
                            </td>
                            <td role='cell'>
                              <div className=' overflow-hidden'>
                                <button
                                  onClick={() => clickDeatilAsset(item.id)}
                                  className='d-block table-elipses text-btn'
                                >
                                  {item?.description}
                                </button>
                              </div>
                            </td>
                            <td role='cell'>
                              <div className=' overflow-hidden'>
                                <div className='d-block table-elipses'>
                                  <span
                                    className={`d-inline-block align-items-center px-3 py-2 rounded ${getAssetStatusClass(item?.status)}`}
                                  >
                                    {item?.status}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td role='cell'>
                              <div>
                                {item?.assigneeType === 1 ? (
                                  <span>
                                    {`${item?.assignedPerson?.firstName || ''} ${
                                      item?.assignedPerson?.lastName || ''
                                    }`}
                                  </span>
                                ) : item?.assigneeType === 2 ? (
                                  <span>
                                    {`${item?.assignedSite?.name || ''} ${
                                      item?.assignedSite?.name && item?.location?.location
                                        ? '/'
                                        : ''
                                    } ${item?.location?.location || ''}`}
                                  </span>
                                ) : (
                                  <span></span>
                                )}
                              </div>
                            </td>
                            <td role='cell'>
                              <div className=' overflow-hidden'>
                                <span className='d-block table-elipses'>{item?.site?.name}</span>
                              </div>
                            </td>
                            <td role='cell'>
                              <div className=' overflow-hidden'>
                                <span className='d-block table-elipses'>
                                  {item?.location?.location}
                                </span>
                              </div>
                            </td>
                            <td role='cell'>
                              <div className=' overflow-hidden'>
                                <span className='d-block table-elipses'>
                                  {`${item?.leased?.firstName || ''}  ${item?.leased?.lastName || ''}`}
                                </span>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                            No records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className=' d-flex align-items-center justify-content-end'>
              <button
                className='btn btn-light-primary   main-btn-style me-2'
                onClick={props.handleCancel}
              >
                Close
              </button>
              <button
                className='btn btn-primary main-btn-style'
                disabled={selected?.length > 0 ? false : true}
                onClick={handleClose}
              >
                Add to List
              </button>
            </div>
            {assetsData?.assets?.length ? (
              <div className='d-sm-flex align-items-center justify-content-between flex-wrap mt-3'>
                <div className='m-1'>
                  Showing {startRecord} to {endRecord} of {totalRecords} records
                </div>
                {pagination && (
                  <Pagination
                    className='m-1 custom-pagination'
                    defaultCurrent={1}
                    pageSize={props?.limit}
                    current={pagination?.page}
                    total={pagination?.total}
                    onChange={handlePageChange}
                    locale={localeInfo}
                  />
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </Modal.Body>
        <Loader loading={loading} />
      </Modal>
      <AssetMinimalDetailModal
        show={modalShow}
        onHide={() => {
          setLoading(false)
          setModalShow(false)
        }}
        singleAssetData={singleAssetData}
        assetId={assetId}
      />
    </>
  )
}

export default CheckinModal
