import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './invoice.css'
import { decrypt } from 'config'

interface Plan {
  description: string
  assetCount: number
  isImportExport: boolean
}

interface BillingDetail {
  id: number
  plan: Plan
  subscriptionStartDate: string
  subscriptionType: string
  paymentStatus: boolean
  invoiceDownloadUrl: string
}

interface BillingDetailsProps {
  billingDetails: BillingDetail[]
}

const InvoiceDetails: React.FC<BillingDetailsProps> = ({ billingDetails }) => {
  return (
    <div className='card detail-invoices'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Invoices Details</h3>
        </div>
      </div>
      <div className='card-footer table-responsive'>
        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th>#</th>
              <th>SUBSCRIPTION DATE</th>
              <th>DESCRIPTION</th>
              <th>AssetCount</th>
              <th>Subscription Type</th>
              <th>View Invoice</th>
            </tr>
          </thead>
          <tbody>
            {billingDetails && billingDetails.length > 0 ? (
              billingDetails.map((detail, index) => (
                <tr key={index}>
                  <td className='table-elipses'>{index + 1}</td>
                  <td className='table-elipses'>
                    {new Date(detail?.subscriptionStartDate).toLocaleDateString()}
                  </td>
                  <td className='table-elipses'>{detail.plan?.description}</td>
                  <td className='table-elipses'>{detail.plan?.assetCount}</td>
                  <td className='table-elipses'>{detail?.subscriptionType}</td>
                  <td>
                    <Link to={`/account-details/invoice-details/${detail?.id}`}>
                      <i className='fa-regular fa-eye'></i>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr role='row' className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <td role='cell' colSpan={6} className='text-center'>
                  <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                    No records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default InvoiceDetails
