import { useRepairAssetMutation } from 'App/Modules/Services/AssetActions'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getCurrentDate, priceRegex } from 'config'
import Loader from 'App/Loaders/BeatLoader'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface NewAuditModalProps {
  repairModal: boolean
  handleClose: () => void
  assetId: any
  refetchAssetDetails: () => void
  setRefreshHistory: any
  currencyObject: any
}

const validationSchema = Yup.object().shape({
  scheduleDate: Yup.string().required('Schedule Date is required.'),
  completeDate: Yup.string().nullable(),
  assignedTo: Yup.string().max(20, 'assignedTo must be at most 20 characters').nullable(),
  repairCost: Yup.string().matches(priceRegex, 'Repair Cost must be at most 15 digits').nullable(),
  notes: Yup.string().max(250, 'Notes must be at most 250 characters').nullable(),
})

const RepairModal: React.FC<NewAuditModalProps> = ({
  repairModal,
  handleClose,
  assetId,
  refetchAssetDetails,
  setRefreshHistory,
  currencyObject,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [repairStatus] = useRepairAssetMutation()
  const { saveAuth, setCurrentUser } = useAuth()
  const today = new Date().toISOString().slice(0, 10)

  const formik = useFormik({
    initialValues: {
      assetId,
      scheduleDate: getCurrentDate(),
      assignedTo: '',
      completeDate: '',
      repairCost: '',
      notes: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const processedValues = {
        ...values,
        completeDate: values.completeDate || null,
        repairCost: values.repairCost || null,
      }
      try {
        setLoading(true)
        const result: any = await repairStatus(processedValues)
        if (result?.data?.success) {
          refetchAssetDetails()
          setRefreshHistory((prev) => !prev)
          toast.success(result?.data?.message)
          handleClose()
          setLoading(false)
        } else {
          toast.error('Failed to update asset repair status')
          checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
        }
      } catch (error: any) {
        toast.error('Failed to update asset repair status')
      }
    },
  })

  const splitName = currencyObject?.name.split(' - ')
  const currencyName = splitName && splitName[1]
  const currencySymbol = splitName && splitName[2]

  return (
    <Modal show={repairModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Repair Asset</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='formScheduleDate'>
            <Form.Label className='required-label'>Schedule Date</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='scheduleDate'
                value={formik.values.scheduleDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.scheduleDate && formik.touched.scheduleDate}
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
            {formik.errors.scheduleDate && formik.touched.scheduleDate && (
              <div className='text-danger'>{formik.errors.scheduleDate}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formAssignedTo'>
            <Form.Label>Assigned To</Form.Label>
            <Form.Control
              type='text'
              name='assignedTo'
              value={formik.values.assignedTo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.assignedTo && formik.touched.assignedTo && (
              <div className='text-danger'>{formik.errors.assignedTo}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formCompleteDate'>
            <Form.Label>Date Completed</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='completeDate'
                value={formik.values.completeDate}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  const inputDate = e.target.value;
                  const selectedDate = new Date(inputDate);
                  const todayDate = new Date(today);
                  if (isNaN(selectedDate.getTime()) || selectedDate < todayDate) {
                    formik.setFieldValue('completeDate', today);
                    e.target.value = today;
                  }
                }}
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formRepairCost'>
            <Form.Label>Repair Cost</Form.Label>
            <InputGroup>
              <InputGroup.Text className='bg-primary text-light'>{currencySymbol}</InputGroup.Text>
              <Form.Control
                type='text'
                name='repairCost'
                value={formik.values.repairCost}
                onChange={(e) => {
                  const value = e.target.value
                  if (/^\d*$/.test(value)) {
                    formik.handleChange(e)
                  }
                }}
                onBlur={formik.handleBlur}
                placeholder={currencyName}
              />
            </InputGroup>
            {formik.errors.repairCost && formik.touched.repairCost && (
              <div className='text-danger'>{formik.errors.repairCost}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formNotes'>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              name='notes'
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.notes && formik.touched.notes && (
              <div className='text-danger'>{formik.errors.notes}</div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
          <button type='submit' className='btn btn-primary' disabled={!formik.values.scheduleDate}>
            Repair
          </button>
        </Modal.Footer>
      </Form>
      <Loader loading={loading} />
    </Modal>
  )
}

export default RepairModal
