import { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { checkUserUnauthorized, useAuth } from '../../../../Auth'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useAddCategoryMutation, useUpdateCategoryMutation } from '../../../../Services/Categories'
import { multiSpace } from 'config'

const categorySchema = Yup.object().shape({
  category: Yup.string()
    .trim()
    .min(3, 'Minimum 3 characters')
    .max(20, 'Maximum 20 characters')
    .required('Category is required'),
})

const initialValues = {
  category: '',
}

const CategoryModel = (props: any) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [addCategory, { isLoading: isAdding }] = useAddCategoryMutation()
  const [updateCategory, { isLoading: isUpdating }] = useUpdateCategoryMutation()

  useEffect(() => {
    if (props?.data?.id) {
      formik.setValues({
        category: props?.data?.category,
      })
    }
  }, [props?.data?.id, props.data])

  const formik = useFormik({
    initialValues,
    validationSchema: categorySchema,
    onSubmit: async (values: any) => {
      try {
        if (currentUser) {
          values.category = values?.category?.trim().replace(multiSpace, ' ')
          const data = props.data?.id
            ? await updateCategory({ id: props?.data?.id, values }).unwrap()
            : await addCategory({ ...values }).unwrap()
          if (data) {
            formik.resetForm()
            toast.dismiss()
            if (data?.success) {
              toast.success(data?.message)
              props.handleClose()
              props.setLoading(true)
            } else {
              toast.error(data?.message)
            }
          }
        }
      } catch (error: any) {
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
    },
  })

  const handleClose = (e: any) => {
    e?.preventDefault()
    formik.resetForm()
    props.handleClose()
  }

  const handleHide = () => {
    formik.resetForm()
    props.handleClose()
  }

  const formikSubmit = (e: any) => {
    e?.preventDefault()
    formik.handleSubmit()
  }

  return (
    <Modal show={props.show} onHide={handleHide} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{props?.data?.id ? 'Edit Category' : 'Add a Category'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form noValidate className='form'>
          <p className='fs-6 mb-4'>
            If you want to add a new category of assets, you’re in the right spot. Add a category
            for computer equipment, wireless keyboards, or any assets you’re working with.
          </p>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark required' htmlFor='category'>
              Category
            </label>
            <div>
              <input
                placeholder='Category'
                id='category'
                {...formik.getFieldProps('category')}
                className={clsx(
                  'form-control  form-control-solid',
                  { 'is-invalid': formik.touched.category && formik.errors.category },
                  {
                    'is-valid': formik.touched.category && !formik.errors.category,
                  }
                )}
                type='text'
                name='category'
                autoComplete='off'
              />
            </div>
            {formik.touched.category && formik.errors.category && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    {typeof formik.errors.category === 'string' && formik.errors.category}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className=' d-flex align-items-center justify-content-end'>
            <button
              className='btn btn-light-primary   main-btn-style me-2'
              onClick={(e) => handleClose(e)}
            >
              Close
            </button>
            <button
              type='submit'
              onClick={(e) => formikSubmit(e)}
              className='btn btn-primary main-btn-style'
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              {isAdding || isUpdating ? (
                <span className='indicator-progress d-block'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <span className='indicator-label'>
                  {props.data?.id ? 'Update Category' : 'Add Category'}
                </span>
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CategoryModel
