import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getCurrentDate } from 'config'
import { useUpdateAssetEventMutation } from 'App/Modules/Services/Assets'
import { useDispatch } from 'react-redux'
import { refresh } from 'App/Store'
import { useNavigate } from 'react-router-dom'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface NewAuditModalProps {
  editRepairEventModal: boolean
  handleClose: () => void
  assetId: any
  event: any
  currencyObject: any
}

const validationSchema = Yup.object().shape({
  scheduleDate: Yup.string().required('Schedule Date is required.'),
  completeDate: Yup.string().nullable(),
  notes: Yup.string().max(250, 'Note should not exceed 250 characters'),
})

const EditRepairEventModal: React.FC<NewAuditModalProps> = ({
  editRepairEventModal,
  handleClose,
  assetId,
  event,
  currencyObject,
}) => {
  const [updateAssetEvent, { isLoading }] = useUpdateAssetEventMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentDate = getCurrentDate()
  const { saveAuth, setCurrentUser } = useAuth()
  const today = new Date().toISOString().slice(0, 10)

  const formik = useFormik({
    initialValues: {
      assetId,
      scheduleDate: event.repair.scheduleDate
        ? new Date(event.repair.scheduleDate).toISOString().slice(0, 10)
        : currentDate,
      assignedTo: event.repair.assignedTo || '',
      completeDate: event.repair.completeDate
        ? new Date(event.repair.scheduleDate).toISOString().slice(0, 10)
        : '',
      repairCost: event.repair.repairCost || '',
      notes: event.repair.notes || '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const processedValues = {
        ...values,
        completeDate: values.completeDate || null,
        repairCost: values.repairCost || null,
      }
      try {
        const payload = {
          eventId: event?.id,
          data: {
            scheduleDate: processedValues.scheduleDate,
            completeDate: processedValues.completeDate,
            assignedTo: processedValues.assignedTo,
            repairCost: processedValues.repairCost,
            notes: processedValues.notes,
          },
        }
        const result: any = await updateAssetEvent(payload)
        if (result?.data?.success) {
          dispatch(refresh(true))
          toast.success(result.data.message)
          handleClose()
          navigate(`/asset/view/${assetId}`)
        } else {
          toast.error('Failed to update asset repair status')
          checkUserUnauthorized(result?.error?.data, saveAuth, setCurrentUser, toast)
        }
      } catch (error: any) {
        toast.error('Failed to update asset repair status')
      }
    },
  })

  const splitName = currencyObject?.name.split(' - ')
  const currencyName = splitName && splitName[1]
  const currencySymbol = splitName && splitName[2]

  return (
    <Modal show={editRepairEventModal} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Repair Asset</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='formScheduleDate'>
            <Form.Label className='required-label'>Schedule Date</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='scheduleDate'
                value={formik.values.scheduleDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.scheduleDate && formik.touched.scheduleDate}
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
            {formik.errors.scheduleDate && formik.touched.scheduleDate && (
              <div className='text-danger'>{formik.errors.scheduleDate}</div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='formAssignedTo'>
            <Form.Label>Assigned To</Form.Label>
            <Form.Control
              type='text'
              name='assignedTo'
              value={formik.values.assignedTo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formCompleteDate'>
            <Form.Label>Date Completed</Form.Label>
            <InputGroup>
              <Form.Control
                type='date'
                name='completeDate'
                value={formik.values.completeDate}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  const inputDate = e.target.value;
                  const selectedDate = new Date(inputDate);
                  const todayDate = new Date(today);
                  if (isNaN(selectedDate.getTime()) || selectedDate < todayDate) {
                    formik.setFieldValue('completeDate', today);
                    e.target.value = today;
                  }
                }}
              />
              <InputGroup.Text className='bg-primary'>
                <i className='fa fa-calendar-alt fs-2 text-light' />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formRepairCost'>
            <Form.Label>Repair Cost</Form.Label>
            <InputGroup>
              <InputGroup.Text className='bg-primary text-light'>{currencySymbol}</InputGroup.Text>
              <Form.Control
                type='text'
                name='repairCost'
                value={formik.values.repairCost}
                onChange={(e) => {
                  const value = e.target.value
                  if (/^\d*$/.test(value)) {
                    formik.handleChange(e)
                  }
                }}
                onBlur={formik.handleBlur}
                placeholder={currencyName}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className='mb-3' controlId='formNotes'>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              name='notes'
              value={formik.values.notes}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{String(formik.errors.notes)}</div>
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn cancel-btn border' onClick={handleClose}>
            Cancel
          </button>
          <button type='submit' className='btn btn-primary' disabled={!formik.values.scheduleDate}>
            Update
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditRepairEventModal
