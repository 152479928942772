import { Dispatch, useEffect, useState, SetStateAction } from 'react'
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MainPhotosUploadModal from './MainPhotosUploadModal'
import {
  useAddStockImageMutation,
  useGetAllPhotosQuery,
  useGetStockImageByCategoryQuery,
  useGetStockImageCategoriesQuery,
} from 'App/Modules/Services/AssetPhotos'
import Loader from 'App/Loaders/BeatLoader'
import { toast } from 'react-toastify'
import { toAbsoluteUrlImage } from 'Template/helpers'
import { useNavigate, useNavigation, useParams } from 'react-router'
import SinglePhotoUploadModal from './SinglePhotoUploadModal'
import MultiplePhotosUploadModal from './MultiplePhotoUploadModal'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'

interface AddPhotostModalProps {
  addPhotosModal: boolean
  handleClose: () => void
  setEditPhoto?: Dispatch<SetStateAction<number>>
}

const AddPhotosModal: React.FC<AddPhotostModalProps> = ({
  addPhotosModal,
  handleClose,
  setEditPhoto,
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { saveAuth, setCurrentUser } = useAuth()
  const path = localStorage.getItem('path')
  const [mainPhotosUploadModal, setMainPhotosUploadModal] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<string>('your-uploads')
  const [stockActiveTab, setStockActiveTab] = useState<number>(2)
  const [myUploads, setMyUploads] = useState<any>([])
  const [assetStockedImages, setAssetStockedImages] = useState<any>([])
  const [categoryId, setCategoryId] = useState<number>(0)
  const [categoryName, setCategoryName] = useState<string>('')
  const [singlePhotoUploadModal, setSinglePhotoUploadModal] = useState<boolean>(false)
  const [multiplesPhotoUploadModal, setMultiplesPhotoUploadModal] = useState<boolean>(false)
  const [addStockPhoto, { isLoading: isStockImageUpload }] = useAddStockImageMutation()
  const { data, isSuccess, isError, error, isLoading }: any = useGetAllPhotosQuery(id)
  const {
    data: categoryData,
    isError: isCategoryError,
    error: categoryError,
    isLoading: isCategories,
  }: any = useGetStockImageCategoriesQuery()
  const {
    data: stockImages,
    isError: isStockError,
    error: stockError,
    isLoading: isStockImages,
  }: any = useGetStockImageByCategoryQuery({ categoryId }, { skip: !categoryId })
  const toggleMainPhotosUploadModal = (): void => setMainPhotosUploadModal(!mainPhotosUploadModal)

  const toggleSinglePhotoUploadModal = (): void =>
    setSinglePhotoUploadModal(!singlePhotoUploadModal)

  const toggleMultiplesPhotoUploadModal = (): void =>
    setMultiplesPhotoUploadModal(!multiplesPhotoUploadModal)

  useEffect(() => {
    if (isSuccess && data) {
      setMyUploads(data?.photos.filter((photo) => photo.uploadType === 1))
      setAssetStockedImages(data?.photos.filter((photo) => photo.uploadType === 2))
    } else if (isError && error) {
      toast.error(error?.response?.data?.message)
    }
  }, [data, isSuccess, isError, error])

  useEffect(() => {
    if (categoryData) {
      setCategoryId(categoryData?.categories[0]?.id)
    } else if (isCategoryError && categoryError) {
      toast.error(categoryError?.data?.message)
      checkUserUnauthorized(categoryError?.data, saveAuth, setCurrentUser, toast)
    }
  }, [categoryData, isCategoryError, categoryError])

  useEffect(() => {
    if (isStockError && stockError) {
      toast.error(stockError?.data?.message)
      checkUserUnauthorized(stockError?.data, saveAuth, setCurrentUser, toast)
    }
  }, [stockImages, isStockError, stockError])

  const handleStockImageUpload = async (stockImageId) => {
    try {
      const res: any = await addStockPhoto({ id: stockImageId, assetId: id })
      if (res?.data?.success) {
        toast.success(res?.data?.message)
        if (setEditPhoto) {
          setStockActiveTab(1)
        } else {
          handleClose()
        }
      } else {
        toast.error(res?.error?.data?.message)
        checkUserUnauthorized(res?.error?.data, saveAuth, setCurrentUser, toast)
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message)
    }
  }

  const handleEditChange = (id) => {
    if (setEditPhoto) {
      setEditPhoto(id)
      handleClose()
    }
  }

  return (
    <>
      <Modal
        className='add-potos-modal'
        show={addPhotosModal}
        onHide={() => {
          setCategoryId(0)
          navigate(`${path}`)
          handleClose()
        }}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='nav nav-tabs gallery-tabs'>
            <li className='nav-item'>
              <a
                className={`nav-link ${activeTab === 'your-uploads' ? 'active' : ''}`}
                data-bs-toggle='tab'
                href='#your-uploads'
                onClick={() => setActiveTab('your-uploads')}
              >
                Your Uploads
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link ${activeTab === 'stock-images' ? 'active' : ''}`}
                data-bs-toggle='tab'
                href='#stock-images'
                onClick={() => setActiveTab('stock-images')}
              >
                Stock Images
              </a>
            </li>
          </ul>
          <div className='tab-content mt-3'>
            {activeTab === 'your-uploads' && (
              <div
                id='your-uploads'
                className={`upload-tab tab-pane fade ${activeTab === 'your-uploads' ? 'show active' : ''}`}
              >
                <>
                  <div className='d-flex justify-content-end'>
                    <Button
                      variant='primary main-photos-upload-modal'
                      onClick={toggleMainPhotosUploadModal}
                    >
                      <i className='fas fa-upload'></i> Upload
                    </Button>
                  </div>
                  <div className='image-grid'>
                    {myUploads.length > 0 ? (
                      myUploads.map((photo, index) => (
                        <div
                          key={index}
                          className={`image-item ${setEditPhoto ? 'cursor-pointer' : ''}`}
                          onClick={() => handleEditChange(photo)}
                        >
                          <img
                            src={toAbsoluteUrlImage(photo?.photo)}
                            alt='Your Upload'
                            crossOrigin='anonymous'
                            className='img-fluid center-block asset_img'
                          />
                        </div>
                      ))
                    ) : (
                      <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                        no photos found
                      </div>
                    )}
                  </div>
                </>
              </div>
            )}
            {activeTab === 'stock-images' && (
              <div
                id='stocked-images'
                className={`my-4 ${activeTab === 'stock-images' ? 'show active' : ''}`}
              >
                <ul className='nav nav-pills'>
                  <li className='nav-item'>
                    <a
                      className={`nav-link ${stockActiveTab === 1 ? 'active' : ''}`}
                      data-bs-toggle='tab'
                      href='#asset-stock-images'
                      onClick={() => setStockActiveTab(1)}
                    >
                      {' '}
                      Asset Stock Images
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className={`nav-link ${stockActiveTab === 2 ? 'active' : ''}`}
                      data-bs-toggle='tab'
                      href='#stocked-images'
                      onClick={() => setStockActiveTab(2)}
                    >
                      Add Stock Image
                    </a>
                  </li>
                </ul>
                <div className='tab-content mt-3'>
                  <div
                    className={`stock-tab tab-pane fade ${stockActiveTab === 1 ? 'show active' : ''}`}
                  >
                    <Row>
                      <Col md={9}>
                        <div className='tab-content'>
                          <div className='image-grid'>
                            {assetStockedImages.length > 0 ? (
                              assetStockedImages.map((photo, index) => (
                                <div
                                  key={index}
                                  className={`image-item ${setEditPhoto ? 'cursor-pointer' : ''}`}
                                  onClick={() => handleEditChange(photo)}
                                >
                                  <img
                                    src={photo?.stockImage?.imgUrl}
                                    alt='Your Upload'
                                    crossOrigin='anonymous'
                                    className='img-fluid center-block asset_img'
                                  />
                                </div>
                              ))
                            ) : (
                              <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                no photos found
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className={`stock-tab tab-pane fade ${stockActiveTab === 2 ? 'show active' : ''}`}
                  >
                    <Row>
                      <Col md={3}>
                        <Nav
                          variant='pills'
                          className='flex-column'
                          defaultActiveKey={`#${categoryData?.categories[0]?.id}`}
                        >
                          {categoryData?.categories && categoryData?.categories?.length > 0 ? (
                            <>
                              {categoryData?.categories?.map((category) => {
                                return (
                                  <Nav.Item>
                                    <Nav.Link
                                      href={`#${category.id}`}
                                      onClick={() => {
                                        setCategoryId(category.id)
                                        setCategoryName(category?.name)
                                      }}
                                    >
                                      {category?.name}
                                    </Nav.Link>
                                  </Nav.Item>
                                )
                              })}
                            </>
                          ) : (
                            <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                              no catehory found
                            </div>
                          )}
                        </Nav>
                      </Col>
                      <Col md={9}>
                        <div className='tab-content'>
                          <div id={`categoryId`} className='tab-pane fade show active'>
                            {stockImages?.images && stockImages?.images?.length > 0 ? (
                              <div className='image-grid'>
                                {stockImages?.images?.map((image) => (
                                  <div
                                    key={image?.id}
                                    className='image-item cursor-pointer'
                                    onClick={() => handleStockImageUpload(image?.id)}
                                  >
                                    <img
                                      src={image?.imgUrl}
                                      alt={categoryName}
                                      crossOrigin='anonymous'
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                no images found
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Loader loading={isLoading || isCategories || isStockImages || isStockImageUpload} />
        </Modal.Body>
      </Modal>
      {singlePhotoUploadModal && (
        <SinglePhotoUploadModal
          handlePhotosClose={handleClose}
          singlePhotoUploadModal={singlePhotoUploadModal}
          handleClose={toggleSinglePhotoUploadModal}
        />
      )}
      {multiplesPhotoUploadModal && (
        <MultiplePhotosUploadModal
          handlePhotosClose={handleClose}
          multiplesPhotoUploadModal={multiplesPhotoUploadModal}
          handleClose={toggleMultiplesPhotoUploadModal}
        />
      )}
      {mainPhotosUploadModal && (
        <MainPhotosUploadModal
          setMultiplesPhotoUploadModal={setMultiplesPhotoUploadModal}
          setSinglePhotoUploadModal={setSinglePhotoUploadModal}
          setMainPhotosUploadModal={setMainPhotosUploadModal}
          mainPhotosUploadModal={mainPhotosUploadModal}
          handleClose={toggleMainPhotosUploadModal}
        />
      )}
    </>
  )
}

export default AddPhotosModal
