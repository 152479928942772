import React from 'react'
import './SuccessPayment.css'
import { useGetSubscriptionQuery } from 'App/Modules/Services/Payments'
import { dateFormat, decrypt } from 'config'

const SuccessPayment: React.FC = () => {
  const { data: activePlanDetails, isLoading: activePlanDetailsLoading } = useGetSubscriptionQuery(
    {}
  )

  const queryParams = new URLSearchParams(window.location.search)
  const message = queryParams.get('message') || 'Success'
  return (
    <div className='main-success'>
      <div className='success-container'>
        <div className='success-icon d-flex justify-content-center align-items-center'>
          <div className='success-bg d-flex justify-content-center align-items-center'>
            <i className='fa-solid fa-check'></i>
          </div>
        </div>
        <h2 className='success-title'>{message}</h2>
        <p className='success-description'>
          {' '}
          Your subscription has been activated and is valid until{' '}
          {dateFormat(decrypt(activePlanDetails?.activeSubscription?.subscriptionEndDate))}. Thank
          you for your purchase.
        </p>
        <a href='/dashboard' className='success-button'>
          Go to Dashboard
        </a>
      </div>
    </div>
  )
}

export default SuccessPayment
