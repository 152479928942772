import Modal from 'react-bootstrap/Modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { defaultMaintenanceDetail as initialValues } from '../../Core/Model'
import {
  useAddMaintenanceMutation,
  useDeleteMaintenanceMutation,
  useUpdateMaintenanceMutation,
} from 'App/Modules/Services/Maintenance'
import { useEffect, useState } from 'react'
import { checkUserUnauthorized, useAuth } from '../../../Auth'
import moment from 'moment'
import DeleteConfirmationModal from 'App/Modules/Apps/CompanySetup/Asset/Components/Modals/DeleteConfirmationModal'
import { dateOptions, days, decimalPlacesRegex, months, nonNumericKeys } from 'config'

const accountDetailsSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('Title is required')
    .max(20, 'Title should not exceed 20 characters')
    .test('no-empty-spaces', 'Title should not be empty', (value = '') => {
      return !/^\s*$/.test(value)
    }),
})
function MaintenancesEditModal(props) {
  const [updateMaintenance, { isLoading: isUpdating }] = useUpdateMaintenanceMutation()
  const [deleteMaintenance, { isLoading: isDeleted }] = useDeleteMaintenanceMutation()
  const [repeatingFrequency, setRepeatingFrequency] = useState('')
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deletedId, setDeletedId] = useState(0)
  const { saveAuth, setCurrentUser } = useAuth()

  useEffect(() => {
    if (props.assetMaintenance?.id) {
      if (props.assetMaintenance?.frequency) {
        props.setRepeatingMaintenance(true)
      }
      if (props.assetMaintenance?.frequency) {
        setRepeatingFrequency(props.assetMaintenance?.frequency)
      }
      formik.setValues({
        title: props.assetMaintenance?.title,
        details: props.assetMaintenance?.details,
        dueDate:
          props.assetMaintenance?.dueDate &&
          moment(props.assetMaintenance?.dueDate).format('YYYY-MM-DD'),
        maintenanceBy: props.assetMaintenance?.maintenanceBy,
        status: props.assetMaintenance?.status,
        dateCompleted:
          props.assetMaintenance?.dateCompleted &&
          moment(props.assetMaintenance?.dateCompleted).format('YYYY-MM-DD'),
        cost: props.assetMaintenance?.cost,
        repeating: props.assetMaintenance?.repeating,
        frequency: props.assetMaintenance?.frequency,
        recurOnEvery: props.assetMaintenance?.recurOnEvery,
        recurOn: props.assetMaintenance?.recurOn,
      })
    }
  }, [props.assetMaintenance?.id])

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: accountDetailsSchema,
    onSubmit: async (values) => {
      try {
        const data = {
          ...values,
          repeating: props.repeatingMaintenance,
          frequency: props.repeatingMaintenance ? repeatingFrequency : null,
          recurOn: props.repeatingMaintenance ? values?.recurOn : null,
          recurOnEvery: props.repeatingMaintenance ? values?.recurOnEvery : null,
          dueDate: values?.dueDate === '' ? null : values?.dueDate,
          dateCompleted: values?.dateCompleted === '' ? null : values?.dateCompleted,
        }
        if (data.frequency) {
          switch (data.frequency.toLowerCase()) {
            case 'daily':
              data.frequency = '1'
              break
            case 'weekly':
              data.frequency = '2'
              break
            case 'monthly':
              data.frequency = '3'
              break
            case 'yearly':
              data.frequency = '4'
              break
            default:
              data.frequency = ''
              break
          }
        }
        const result: any = await updateMaintenance({
          body: data,
          id: props.assetMaintenance?.id,
        }).unwrap()
        if (result?.success) {
          toast.success(result?.message)
          props.setShowEditModal(false)
          props.setShowModal(true)
          props.setLoading(true)
        }
      } catch (error: any) {
        toast.error(error?.data?.message)
        checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
      }
    },
  })
  const handleConfirmDelete = () => {
    setConfirmDelete(false)
  }

  const handleDelete = async () => {
    try {
      const res: any = await deleteMaintenance(deletedId)
      if (res?.data?.success) {
        toast.dismiss()
        toast.success(res?.data?.message)
        props.onHide()
      } else {
        toast.dismiss()
        toast.error(res?.error?.message)
        checkUserUnauthorized(res?.error, saveAuth, setCurrentUser, toast)
      }
    } catch (error: any) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
    }
  }

  useEffect(() => {
    if (repeatingFrequency !== props.assetMaintenance?.frequency) {
      const data = {
        ...formik.values,
        recurOnEvery: null,
        recurOn: null,
      }
      formik.setValues(data)
    }
  }, [repeatingFrequency])

  const handleFormSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    formik.submitForm()
  }
  const handleDeleteSubmit = () => {
    setConfirmDelete(true)
    setDeletedId(props?.assetMaintenance?.id)
    formik.resetForm()
  }
  return (
    <>
      <Modal
        className='px-2'
        size='lg'
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        backdrop='static'
      >
        <Modal.Header closeButton onClick={props.onHide}>
          <Modal.Title>Update Maintenance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='inner-site-modal'>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>
                  {' '}
                  Title{' '}
                </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      {...formik.getFieldProps('title')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.title}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Details</label>
                <div className='col-lg-9 fv-row'>
                  <textarea
                    {...formik.getFieldProps('details')}
                    onChange={(e) => {
                      formik.handleChange(e)
                    }}
                    rows={3}
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fw-bold fs-6'>Due Date</label>
                <div className='col-lg-9 fv-row'>
                  <div className='input-group'>
                    <input
                      type='date'
                      className='form-control  form-control-solid'
                      aria-label='Amount (to the nearest dollar)'
                      {...formik.getFieldProps('dueDate')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                    />
                    <span className='input-group-text border-0'>
                      <i className='fa fa-calendar'></i>
                    </span>
                  </div>
                  {formik.touched.dueDate && formik.errors.dueDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.dueDate}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'> Maintenance By </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      {...formik.getFieldProps('maintenanceBy')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                  {formik.touched.maintenanceBy && formik.errors.maintenanceBy && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.maintenanceBy}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span>Maintenance Status</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-control form-control-solid form-select-solid  '
                    {...formik.getFieldProps('status')}
                    onChange={(e) => {
                      formik.handleChange(e)
                    }}
                  >
                    <option value=''>Select ...</option>
                    <option value='Scheduled'>Scheduled</option>
                    <option value='In Progress'>In progress</option>
                    <option value='On Hold'>On hold</option>
                    <option value='Cancelled'>Cancelled</option>
                    <option value='Completed'>Completed</option>
                  </select>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Date Completed</label>
                <div className='col-lg-9 fv-row'>
                  <div className='input-group'>
                    <input
                      type='date'
                      className='form-control  form-control-solid'
                      {...formik.getFieldProps('dateCompleted')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      min={
                        formik.values.dueDate
                          ? new Date(formik.values.dueDate).toISOString().split('T')[0]
                          : new Date().toISOString().split('T')[0]
                      }
                    />
                    <span className='input-group-text border-0'>
                      <i className='fa fa-calendar'></i>
                    </span>
                  </div>
                  {formik.touched.dateCompleted && formik.errors.dateCompleted && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.dateCompleted}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Maintenance Cost</label>
                <div className='col-lg-9 fv-row'>
                  <div className='input-group currency-field'>
                    <span className='input-group-text'>{props.currencyLogo}</span>
                    <input
                      type='number'
                      min={'1'}
                      className='form-control  form-control-solid'
                      aria-label='Amount (to the nearest dollar)'
                      {...formik.getFieldProps('cost')}
                      onChange={(e) => {
                        const value = e.target.value
                        if (decimalPlacesRegex.test(value)) {
                          formik.setFieldValue('cost', value)
                        }
                      }}
                      onKeyDown={(e) => {
                        nonNumericKeys(e)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-4 align-items-center'>
                <label className='col-lg-3 col-form-label  fw-bold fs-6'>Repeating</label>
                <div className='col-lg-9 fv-row'>
                  <div className='d-flex'>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('repeating')}
                          onClick={() => {
                            props.setRepeatingMaintenance(true)
                            setRepeatingFrequency('daily')
                          }}
                          type='checkbox'
                          value={'yes'}
                          checked={props.repeatingMaintenance == false ? false : true}
                          name={'repeating'}
                          id='repeatingYes'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='repeatingYes'>
                        Yes
                      </label>
                    </div>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('maintenanceReapting')}
                          onClick={() => {
                            props.setRepeatingMaintenance(false)
                            setRepeatingFrequency('')
                          }}
                          value={'no'}
                          checked={props.repeatingMaintenance === false ? true : false}
                          type='checkbox'
                          name={'repeating'}
                          id='repeatingNo'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='repeatingNo'>
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={props.repeatingMaintenance ? 'row mb-6 align-items-center' : 'd-none'}
              >
                <label
                  className='col-lg-3 col-form-label  fw-bold fs-6'
                  htmlFor='maintenanceFrequency'
                >
                  Frequency
                </label>
                <div className='col-lg-9 fv-row'>
                  <div className='d-flex'>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('frequency')}
                          onClick={() => {
                            setRepeatingFrequency('daily')
                          }}
                          type='checkbox'
                          value={'daily'}
                          checked={repeatingFrequency == 'daily' ? true : false}
                          name={'frequency'}
                          id='dailyFrequency'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='dailyFrequency'>
                        Daily
                      </label>
                    </div>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('frequency')}
                          onClick={() => {
                            setRepeatingFrequency('weekly')
                          }}
                          type='checkbox'
                          value={'weekly'}
                          checked={repeatingFrequency == 'weekly' ? true : false}
                          name={'frequency'}
                          id='weeklyFrequency'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='weeklyFrequency'>
                        Weekly
                      </label>
                    </div>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('frequency')}
                          onClick={() => {
                            setRepeatingFrequency('monthly')
                          }}
                          type='checkbox'
                          value={'monthly'}
                          checked={repeatingFrequency == 'monthly' ? true : false}
                          name={'frequency'}
                          id='monthlyFrequency'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='monthlyFrequency'>
                        Monthly
                      </label>
                    </div>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('frequency')}
                          onClick={() => {
                            setRepeatingFrequency('yearly')
                          }}
                          type='checkbox'
                          value={'yearly'}
                          checked={repeatingFrequency == 'yearly' ? true : false}
                          name={'frequency'}
                          id='yearlyFrequency'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='yearlyFrequency'>
                        Yearly
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {repeatingFrequency == 'weekly' ||
                repeatingFrequency == 'monthly' ||
                (repeatingFrequency == 'yearly' && (
                  <div className={props.repeatingMaintenance ? 'row mb-6' : 'd-none'}>
                    <label className='col-lg-3 col-form-label  fw-bold fs-6'>Recur on:</label>
                  </div>
                ))}
              <div
                className={
                  props.repeatingMaintenance &&
                  (repeatingFrequency == 'weekly' || repeatingFrequency == 'monthly')
                    ? 'row mb-6'
                    : 'd-none'
                }
              >
                <label className='col-lg-3 col-form-label  fw-bold fs-6' htmlFor='recurOnEvery'>
                  Every
                </label>
                <div className={props.repeatingMaintenance ? 'col-lg-9 fv-row' : 'd-none'}>
                  <div className='input-group'>
                    <input
                      type='number'
                      min='1'
                      className='form-control  form-control-solid'
                      {...formik.getFieldProps('recurOnEvery')}
                      onChange={(e) => {
                        const value = e.target.value
                        if (decimalPlacesRegex.test(value)) {
                          formik.handleChange(e)
                        }
                      }}
                      onKeyDown={(e) => {
                        nonNumericKeys(e)
                      }}
                      id='recurOnEvery'
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  props.repeatingMaintenance && repeatingFrequency == 'yearly'
                    ? 'row mb-6'
                    : 'd-none'
                }
              >
                <label
                  className={
                    repeatingFrequency ? 'col-lg-3 col-form-label  fw-bold fs-6' : 'd-none'
                  }
                  htmlFor='recurOnEvery'
                >
                  Every
                </label>
                <div className={repeatingFrequency ? 'col-lg-9 fv-row' : 'd-none'}>
                  <div className='input-group'>
                    <select
                      {...formik.getFieldProps('recurOnEvery')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      className='form-select form-control form-control-solid form-select-solid  '
                      id='recurOnEvery'
                    >
                      <option value=''>Select Month</option>
                      {months.map((month) => (
                        <option key={month} value={month.toLowerCase()}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div
                className={
                  props.repeatingMaintenance && repeatingFrequency == 'weekly'
                    ? 'row mb-6'
                    : 'd-none'
                }
              >
                <label
                  className={
                    repeatingFrequency ? 'col-lg-3 col-form-label  fw-bold fs-6' : 'd-none'
                  }
                  htmlFor='recurOn'
                >
                  On
                </label>
                <div className={repeatingFrequency ? 'col-lg-9 fv-row' : 'd-none'}>
                  <div className='input-group'>
                    <select
                      {...formik.getFieldProps('recurOn')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      className='form-select form-control form-control-solid form-select-solid  '
                      id='recurOn'
                    >
                      <option value=''>Select Day</option>
                      {days.map((day) => (
                        <option key={day} value={day.toLowerCase()}>
                          {day}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div
                className={
                  (props.repeatingMaintenance && repeatingFrequency == 'yearly') ||
                  (props.repeatingMaintenance && repeatingFrequency == 'monthly')
                    ? 'row mb-6'
                    : 'd-none'
                }
              >
                <label
                  className={repeatingFrequency ? 'col-lg-3 col-form-label fw-bold fs-6' : 'd-none'}
                  htmlFor='recurOn'
                >
                  On
                </label>
                <div className={repeatingFrequency ? 'col-lg-9 fv-row' : 'd-none'}>
                  <div className='input-group'>
                    <select
                      {...formik.getFieldProps('recurOn')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      className='form-select form-control form-control-solid form-select-solid'
                      id='recurOn'
                    >
                      <option value=''>Select Date</option>
                      {dateOptions.map((date) => (
                        <option key={date} value={date}>
                          {date}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          {!props.permission?.deleteMaintenance && props.staffUser ? (
            ''
          ) : props.assetMaintenance?.id > 0 ? (
            <button
              type='button'
              className='btn btn-danger main-btn-style btn btn-primary'
              disabled={isUpdating || !formik.isValid}
              onClick={handleDeleteSubmit}
            >
              <span className='indicator-label'>Delete</span>
            </button>
          ) : null}
          <div className=' d-flex align-items-center justify-content-start'>
            <button
              onClick={props.onHide}
              type='button'
              className='btn btn-light-primary   main-btn-style me-2'
              data-bs-dismiss='modal'
            >
              Close
            </button>
            <button
              type='button'
              className='btn main-btn-style btn-primary'
              disabled={isUpdating || !formik.isValid}
              onClick={handleFormSubmit}
            >
              {!isUpdating && 'Submit'}
              {isUpdating && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          <DeleteConfirmationModal
            isSetup={'Maintenance'}
            isDeleted={isDeleted}
            handleDelete={handleDelete}
            handleClose={handleConfirmDelete}
            show={confirmDelete}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MaintenancesEditModal
