import { useState } from 'react'
import { useGetAllGroupsQuery } from 'App/Modules/Services/groups'
import { GroupResponse } from '../../SecurityGroups/Core/Model'
import { useNavigate } from 'react-router'

function ManageGroup({ formik, onSelectGroup, userData, selectedGroupName }) {
  const navigate = useNavigate()
  const { data: groupsList } = useGetAllGroupsQuery({})

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <span className='me-2 align-middle pt-1'>
            <i className='la la-key fs-1'></i>
          </span>
          <h3 className='fw-bolder m-0'> Security Group</h3>
        </div>
      </div>
      <div id='kt_two-factor-authentication' className='collapse show'>
        <div className='card-body border-top p-lg-9 p-md-7 p-6'>
          <p className='mb-lg-10 mb-5'>
            Decide which parts of AssetTiger be accessible to this user by assigning a Security
            Group. Select the <strong>Administrator group</strong> to give full access, the{' '}
            <strong>Manager group</strong> to give full access without Admin. Rights, the{' '}
            <strong>Viewer group</strong> to give this user the ability to view the basics of this
            account. Or, you can create your own custom Security Groups.
          </p>
          <div className='row'>
            <div className='offset-xxl-2 col-xxl-8 offset-lg-1 col-lg-10 '>
              <div className='d-flex flex-md-row flex-column align-items-md-start mb-3'>
                <div className='form-group me-md-3 mb-md-0 mb-3 w-100 d-md-flex flex-1'>
                  <label className='col-form-label me-3 required'>Group Name</label>
                  <div className='flex-1'>
                    <select
                      name='recordCount'
                      id='recordCount'
                      className='form-select flex-1'
                      {...formik.getFieldProps('groupId')}
                    >
                      <option value=''>Select Group</option>
                      {groupsList?.groups.map((item: GroupResponse, idx: number) => (
                        <option key={idx} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                    <span
                      className='field-validation-valid'
                      data-valmsg-for='recordCount'
                      data-valmsg-replace='true'
                    ></span>
                    {formik.touched.groupId && formik.errors.groupId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.groupId}</div>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className='btn btn-light-primary main-btn-style'
                  onClick={() => navigate('/group-manager')}
                >
                  Manage Groups
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageGroup
